import TXUI_SortRule_AnyUndefined_64 from './TXUI_SortRule_AnyUndefined.64.png'
import TXUI_SortRule_AnyUndefined_256 from './TXUI_SortRule_AnyUndefined.256.png'
import Fences_64 from './Fences.64.png'
import Fences_256 from './Fences.256.png'
import Foundations_8x1_64 from './Foundations_8x1.64.png'
import Foundations_8x1_256 from './Foundations_8x1.256.png'
import Foundations_8x2_64 from './Foundations_8x2.64.png'
import Foundations_8x2_256 from './Foundations_8x2.256.png'
import Foundations_8x4_64 from './Foundations_8x4.64.png'
import Foundations_8x4_256 from './Foundations_8x4.256.png'
import FrameworkFoundation_64 from './FrameworkFoundation.64.png'
import FrameworkFoundation_256 from './FrameworkFoundation.256.png'
import Foundation_Glass_64 from './Foundation_Glass.64.png'
import Foundation_Glass_256 from './Foundation_Glass.256.png'
import Pillar_Bottom_64 from './Pillar_Bottom.64.png'
import Pillar_Bottom_256 from './Pillar_Bottom.256.png'
import Pillar_Middle_64 from './Pillar_Middle.64.png'
import Pillar_Middle_256 from './Pillar_Middle.256.png'
import Pillar_Top_64 from './Pillar_Top.64.png'
import Pillar_Top_256 from './Pillar_Top.256.png'
import QuarterPipe_01_64 from './QuarterPipe_01.64.png'
import QuarterPipe_01_256 from './QuarterPipe_01.256.png'
import QuarterPipe_02_64 from './QuarterPipe_02.64.png'
import QuarterPipe_02_256 from './QuarterPipe_02.256.png'
import QuarterPipe_Corner_01_64 from './QuarterPipe_Corner_01.64.png'
import QuarterPipe_Corner_01_256 from './QuarterPipe_Corner_01.256.png'
import QuarterPipe_Corner_02_64 from './QuarterPipe_Corner_02.64.png'
import QuarterPipe_Corner_02_256 from './QuarterPipe_Corner_02.256.png'
import IconDesc_Cnr_QuarterPipe_03_64 from './IconDesc_Cnr_QuarterPipe_03.64.png'
import IconDesc_Cnr_QuarterPipe_03_256 from './IconDesc_Cnr_QuarterPipe_03.256.png'
import IconDesc_Cnr_QuarterPipe_04_64 from './IconDesc_Cnr_QuarterPipe_04.64.png'
import IconDesc_Cnr_QuarterPipe_04_256 from './IconDesc_Cnr_QuarterPipe_04.256.png'
import IconDesc_Ladder_64 from './IconDesc_Ladder.64.png'
import IconDesc_Ladder_256 from './IconDesc_Ladder.256.png'
import Ramp8x1_64 from './Ramp8x1.64.png'
import Ramp8x1_256 from './Ramp8x1.256.png'
import Ramps_small_64 from './Ramps_small.64.png'
import Ramps_small_256 from './Ramps_small.256.png'
import Ramps_64 from './Ramps.64.png'
import Ramps_256 from './Ramps.256.png'
import Ramp_8x4_Inverted_64 from './Ramp_8x4_Inverted.64.png'
import Ramp_8x4_Inverted_256 from './Ramp_8x4_Inverted.256.png'
import Ramp_8x8x4_64 from './Ramp_8x8x4.64.png'
import Ramp_8x8x4_256 from './Ramp_8x8x4.256.png'
import TXUI_Foundation_Corner_8x1_64 from './TXUI_Foundation_Corner_8x1.64.png'
import TXUI_Foundation_Corner_8x1_256 from './TXUI_Foundation_Corner_8x1.256.png'
import TXUI_Ramp_Corner_8x1_64 from './TXUI_Ramp_Corner_8x1.64.png'
import TXUI_Ramp_Corner_8x1_256 from './TXUI_Ramp_Corner_8x1.256.png'
import TXUI_Foundation_Corner_8x2_64 from './TXUI_Foundation_Corner_8x2.64.png'
import TXUI_Foundation_Corner_8x2_256 from './TXUI_Foundation_Corner_8x2.256.png'
import TXUI_Ramp_Corner_8x2_64 from './TXUI_Ramp_Corner_8x2.64.png'
import TXUI_Ramp_Corner_8x2_256 from './TXUI_Ramp_Corner_8x2.256.png'
import TXUI_Foundation_Corner_8x4_64 from './TXUI_Foundation_Corner_8x4.64.png'
import TXUI_Foundation_Corner_8x4_256 from './TXUI_Foundation_Corner_8x4.256.png'
import TXUI_Ramp_Corner_8x4_64 from './TXUI_Ramp_Corner_8x4.64.png'
import TXUI_Ramp_Corner_8x4_256 from './TXUI_Ramp_Corner_8x4.256.png'
import IconDesc_Dbl_Ramp_8x8x2_01_64 from './IconDesc_Dbl_Ramp_8x8x2_01.64.png'
import IconDesc_Dbl_Ramp_8x8x2_01_256 from './IconDesc_Dbl_Ramp_8x8x2_01.256.png'
import IconDesc_Dbl_Ramp_8x8x1_01_64 from './IconDesc_Dbl_Ramp_8x8x1_01.64.png'
import IconDesc_Dbl_Ramp_8x8x1_01_256 from './IconDesc_Dbl_Ramp_8x8x1_01.256.png'
import IconDesc_RampInverted_8x8x1_64 from './IconDesc_RampInverted_8x8x1.64.png'
import IconDesc_RampInverted_8x8x1_256 from './IconDesc_RampInverted_8x8x1.256.png'
import IconDesc_C_Inv_Ramp_8x1_01_64 from './IconDesc_C_Inv_Ramp_8x1_01.64.png'
import IconDesc_C_Inv_Ramp_8x1_01_256 from './IconDesc_C_Inv_Ramp_8x1_01.256.png'
import IconDesc_C_Inv_Ramp_8x1_02_64 from './IconDesc_C_Inv_Ramp_8x1_02.64.png'
import IconDesc_C_Inv_Ramp_8x1_02_256 from './IconDesc_C_Inv_Ramp_8x1_02.256.png'
import IconDesc_RampInverted_8x8x2_64 from './IconDesc_RampInverted_8x8x2.64.png'
import IconDesc_RampInverted_8x8x2_256 from './IconDesc_RampInverted_8x8x2.256.png'
import IconDesc_C_Inv_Ramp_8x2_01_64 from './IconDesc_C_Inv_Ramp_8x2_01.64.png'
import IconDesc_C_Inv_Ramp_8x2_01_256 from './IconDesc_C_Inv_Ramp_8x2_01.256.png'
import IconDesc_C_Inv_Ramp_8x2_02_64 from './IconDesc_C_Inv_Ramp_8x2_02.64.png'
import IconDesc_C_Inv_Ramp_8x2_02_256 from './IconDesc_C_Inv_Ramp_8x2_02.256.png'
import IconDesc_C_Inv_Ramp_8x4_01_64 from './IconDesc_C_Inv_Ramp_8x4_01.64.png'
import IconDesc_C_Inv_Ramp_8x4_01_256 from './IconDesc_C_Inv_Ramp_8x4_01.256.png'
import IconDesc_C_Inv_Ramp_8x3_02_64 from './IconDesc_C_Inv_Ramp_8x3_02.64.png'
import IconDesc_C_Inv_Ramp_8x3_02_256 from './IconDesc_C_Inv_Ramp_8x3_02.256.png'
import T_Stair_1b_64 from './T_Stair_1b.64.png'
import T_Stair_1b_256 from './T_Stair_1b.256.png'
import StairLeft_64 from './StairLeft.64.png'
import StairLeft_256 from './StairLeft.256.png'
import StairRight_64 from './StairRight.64.png'
import StairRight_256 from './StairRight.256.png'
import WalkwayCross_64 from './WalkwayCross.64.png'
import WalkwayCross_256 from './WalkwayCross.256.png'
import WalkwayRamp_64 from './WalkwayRamp.64.png'
import WalkwayRamp_256 from './WalkwayRamp.256.png'
import WalkwayStraight_64 from './WalkwayStraight.64.png'
import WalkwayStraight_256 from './WalkwayStraight.256.png'
import WalkwayTCross_64 from './WalkwayTCross.64.png'
import WalkwayTCross_256 from './WalkwayTCross.256.png'
import WalkwayTurn_64 from './WalkwayTurn.64.png'
import WalkwayTurn_256 from './WalkwayTurn.256.png'
import Wall_Orange_64 from './Wall_Orange.64.png'
import Wall_Orange_256 from './Wall_Orange.256.png'
import Wall_Grey_64 from './Wall_Grey.64.png'
import Wall_Grey_256 from './Wall_Grey.256.png'
import Wall_Conveyor_x3_Orange_64 from './Wall_Conveyor_x3_Orange.64.png'
import Wall_Conveyor_x3_Orange_256 from './Wall_Conveyor_x3_Orange.256.png'
import Wall_Conveyor_x3_Grey_64 from './Wall_Conveyor_x3_Grey.64.png'
import Wall_Conveyor_x3_Grey_256 from './Wall_Conveyor_x3_Grey.256.png'
import Wall_Conveyor_x2_Orange_64 from './Wall_Conveyor_x2_Orange.64.png'
import Wall_Conveyor_x2_Orange_256 from './Wall_Conveyor_x2_Orange.256.png'
import Wall_Conveyor_x2_Grey_64 from './Wall_Conveyor_x2_Grey.64.png'
import Wall_Conveyor_x2_Grey_256 from './Wall_Conveyor_x2_Grey.256.png'
import Wall_Conveyor_x1_Orange_64 from './Wall_Conveyor_x1_Orange.64.png'
import Wall_Conveyor_x1_Orange_256 from './Wall_Conveyor_x1_Orange.256.png'
import Wall_Conveyor_x1_Grey_64 from './Wall_Conveyor_x1_Grey.64.png'
import Wall_Conveyor_x1_Grey_256 from './Wall_Conveyor_x1_Grey.256.png'
import Wall_Conveyor_Perpendicular_Orange_64 from './Wall_Conveyor_Perpendicular_Orange.64.png'
import Wall_Conveyor_Perpendicular_Orange_256 from './Wall_Conveyor_Perpendicular_Orange.256.png'
import Wall_Conveyor_Perpendicular_Grey_64 from './Wall_Conveyor_Perpendicular_Grey.64.png'
import Wall_Conveyor_Perpendicular_Grey_256 from './Wall_Conveyor_Perpendicular_Grey.256.png'
import Wall_Door_Center_Orange_64 from './Wall_Door_Center_Orange.64.png'
import Wall_Door_Center_Orange_256 from './Wall_Door_Center_Orange.256.png'
import Wall_Door_Center_Grey_64 from './Wall_Door_Center_Grey.64.png'
import Wall_Door_Center_Grey_256 from './Wall_Door_Center_Grey.256.png'
import Wall_Door_Left_Orange_64 from './Wall_Door_Left_Orange.64.png'
import Wall_Door_Left_Orange_256 from './Wall_Door_Left_Orange.256.png'
import Wall_Door_Left_Grey_64 from './Wall_Door_Left_Grey.64.png'
import Wall_Door_Left_Grey_256 from './Wall_Door_Left_Grey.256.png'
import Wall_Door_Right_Orange_64 from './Wall_Door_Right_Orange.64.png'
import Wall_Door_Right_Orange_256 from './Wall_Door_Right_Orange.256.png'
import Wall_Door_Right_Grey_64 from './Wall_Door_Right_Grey.64.png'
import Wall_Door_Right_Grey_256 from './Wall_Door_Right_Grey.256.png'
import Wall_Orange_Gate_64 from './Wall_Orange_Gate.64.png'
import Wall_Orange_Gate_256 from './Wall_Orange_Gate.256.png'
import Wall_Window_8x4_01_64 from './Wall_Window_8x4_01.64.png'
import Wall_Window_8x4_01_256 from './Wall_Window_8x4_01.256.png'
import Wall_Window_8x4_02_64 from './Wall_Window_8x4_02.64.png'
import Wall_Window_8x4_02_256 from './Wall_Window_8x4_02.256.png'
import Wall_Window_8x4_03_64 from './Wall_Window_8x4_03.64.png'
import Wall_Window_8x4_03_256 from './Wall_Window_8x4_03.256.png'
import Wall_Window_8x4_04_64 from './Wall_Window_8x4_04.64.png'
import Wall_Window_8x4_04_256 from './Wall_Window_8x4_04.256.png'
import AssemblerMk1_64 from './AssemblerMk1.64.png'
import AssemblerMk1_256 from './AssemblerMk1.256.png'
import IconDesc_Blender_64 from './IconDesc_Blender.64.png'
import IconDesc_Blender_256 from './IconDesc_Blender.256.png'
import ConveyorMerger_64 from './ConveyorMerger.64.png'
import ConveyorMerger_256 from './ConveyorMerger.256.png'
import ConveyorSplitter_64 from './ConveyorSplitter.64.png'
import ConveyorSplitter_256 from './ConveyorSplitter.256.png'
import ProgrammableSplitter_64 from './ProgrammableSplitter.64.png'
import ProgrammableSplitter_256 from './ProgrammableSplitter.256.png'
import SmartSplitter_64 from './SmartSplitter.64.png'
import SmartSplitter_256 from './SmartSplitter.256.png'
import IconDesc_CeilingLight_64 from './IconDesc_CeilingLight.64.png'
import IconDesc_CeilingLight_256 from './IconDesc_CeilingLight.256.png'
import ConstructorMk1_64 from './ConstructorMk1.64.png'
import ConstructorMk1_256 from './ConstructorMk1.256.png'
import ConveyorMk1_64 from './ConveyorMk1.64.png'
import ConveyorMk1_256 from './ConveyorMk1.256.png'
import ConveyorMk2_64 from './ConveyorMk2.64.png'
import ConveyorMk2_256 from './ConveyorMk2.256.png'
import ConveyorMk3_64 from './ConveyorMk3.64.png'
import ConveyorMk3_256 from './ConveyorMk3.256.png'
import ConveyorMk4_64 from './ConveyorMk4.64.png'
import ConveyorMk4_256 from './ConveyorMk4.256.png'
import ConveyorMk5_64 from './ConveyorMk5.64.png'
import ConveyorMk5_256 from './ConveyorMk5.256.png'
import ConveyorLiftMK1_64 from './ConveyorLiftMK1.64.png'
import ConveyorLiftMK1_256 from './ConveyorLiftMK1.256.png'
import ConveyorLiftMK2_64 from './ConveyorLiftMK2.64.png'
import ConveyorLiftMK2_256 from './ConveyorLiftMK2.256.png'
import ConveyorLiftMK3_64 from './ConveyorLiftMK3.64.png'
import ConveyorLiftMK3_256 from './ConveyorLiftMK3.256.png'
import ConveyorLiftMK4_64 from './ConveyorLiftMK4.64.png'
import ConveyorLiftMK4_256 from './ConveyorLiftMK4.256.png'
import ConveyorLiftMK5_64 from './ConveyorLiftMK5.64.png'
import ConveyorLiftMK5_256 from './ConveyorLiftMK5.256.png'
import ConveyorPole_Wall_64 from './ConveyorPole_Wall.64.png'
import ConveyorPole_Wall_256 from './ConveyorPole_Wall.256.png'
import IconDesc_DronePort_64 from './IconDesc_DronePort.64.png'
import IconDesc_DronePort_256 from './IconDesc_DronePort.256.png'
import IconDesc_FloodLight_64 from './IconDesc_FloodLight.64.png'
import IconDesc_FloodLight_256 from './IconDesc_FloodLight.256.png'
import IconDesc_FloodlightWall_64 from './IconDesc_FloodlightWall.64.png'
import IconDesc_FloodlightWall_256 from './IconDesc_FloodlightWall.256.png'
import Foundry_64 from './Foundry.64.png'
import Foundry_256 from './Foundry.256.png'
import IconDesc_Extractor_64 from './IconDesc_Extractor.64.png'
import IconDesc_Extractor_256 from './IconDesc_Extractor.256.png'
import IconDesc_Smasher_64 from './IconDesc_Smasher.64.png'
import IconDesc_Smasher_256 from './IconDesc_Smasher.256.png'
import BiomassGenerator_64 from './BiomassGenerator.64.png'
import BiomassGenerator_256 from './BiomassGenerator.256.png'
import CoalGenerator_64 from './CoalGenerator.64.png'
import CoalGenerator_256 from './CoalGenerator.256.png'
import FuelGenerator_64 from './FuelGenerator.64.png'
import FuelGenerator_256 from './FuelGenerator.256.png'
import GeoThermalPowerGenerator_64 from './GeoThermalPowerGenerator.64.png'
import GeoThermalPowerGenerator_256 from './GeoThermalPowerGenerator.256.png'
import NuclearPowerplant_64 from './NuclearPowerplant.64.png'
import NuclearPowerplant_256 from './NuclearPowerplant.256.png'
import IconDesc_HadronCollider_64 from './IconDesc_HadronCollider.64.png'
import IconDesc_HadronCollider_256 from './IconDesc_HadronCollider.256.png'
import IconDesc_ChristmasTree_64 from './IconDesc_ChristmasTree.64.png'
import IconDesc_ChristmasTree_256 from './IconDesc_ChristmasTree.256.png'
import IconDesc_HyperTube_WallHole_64 from './IconDesc_HyperTube_WallHole.64.png'
import IconDesc_HyperTube_WallHole_256 from './IconDesc_HyperTube_WallHole.256.png'
import IconDesc_HyperTube_WallSupport_64 from './IconDesc_HyperTube_WallSupport.64.png'
import IconDesc_HyperTube_WallSupport_256 from './IconDesc_HyperTube_WallSupport.256.png'
import FluidStorageIndustrial_64 from './FluidStorageIndustrial.64.png'
import FluidStorageIndustrial_256 from './FluidStorageIndustrial.256.png'
import IconDesc_JumpPad_64 from './IconDesc_JumpPad.64.png'
import IconDesc_JumpPad_256 from './IconDesc_JumpPad.256.png'
import LandingPad_64 from './LandingPad.64.png'
import LandingPad_256 from './LandingPad.256.png'
import IconDesc_LightsControlPanel_64 from './IconDesc_LightsControlPanel.64.png'
import IconDesc_LightsControlPanel_256 from './IconDesc_LightsControlPanel.256.png'
import LookOutTower_64 from './LookOutTower.64.png'
import LookOutTower_256 from './LookOutTower.256.png'
import MAM_64 from './MAM.64.png'
import MAM_256 from './MAM.256.png'
import Manufacturer_64 from './Manufacturer.64.png'
import Manufacturer_256 from './Manufacturer.256.png'
import MinerMk1_64 from './MinerMk1.64.png'
import MinerMk1_256 from './MinerMk1.256.png'
import MinerMk2_64 from './MinerMk2.64.png'
import MinerMk2_256 from './MinerMk2.256.png'
import MinerMk3_64 from './MinerMk3.64.png'
import MinerMk3_256 from './MinerMk3.256.png'
import OilPump_64 from './OilPump.64.png'
import OilPump_256 from './OilPump.256.png'
import IconDesc_OilRefinery_64 from './IconDesc_OilRefinery.64.png'
import IconDesc_OilRefinery_256 from './IconDesc_OilRefinery.256.png'
import IconDesc_Packager_64 from './IconDesc_Packager.64.png'
import IconDesc_Packager_256 from './IconDesc_Packager.256.png'
import HyperTube_64 from './HyperTube.64.png'
import HyperTube_256 from './HyperTube.256.png'
import HyperTubeStart_64 from './HyperTubeStart.64.png'
import HyperTubeStart_256 from './HyperTubeStart.256.png'
import PipelineJunction_64 from './PipelineJunction.64.png'
import PipelineJunction_256 from './PipelineJunction.256.png'
import IconDesc_Pipes_64 from './IconDesc_Pipes.64.png'
import IconDesc_Pipes_256 from './IconDesc_Pipes.256.png'
import IconDesc_PipeMK2_64 from './IconDesc_PipeMK2.64.png'
import IconDesc_PipeMK2_256 from './IconDesc_PipeMK2.256.png'
import PipeSupportWall_64 from './PipeSupportWall.64.png'
import PipeSupportWall_256 from './PipeSupportWall.256.png'
import PipeSupportWallHole_64 from './PipeSupportWallHole.64.png'
import PipeSupportWallHole_256 from './PipeSupportWallHole.256.png'
import PipePump_64 from './PipePump.64.png'
import PipePump_256 from './PipePump.256.png'
import IconDesc_PipePumpMK2_64 from './IconDesc_PipePumpMK2.64.png'
import IconDesc_PipePumpMK2_256 from './IconDesc_PipePumpMK2.256.png'
import IconDesc_Valve_64 from './IconDesc_Valve.64.png'
import IconDesc_Valve_256 from './IconDesc_Valve.256.png'
import Powerline_64 from './Powerline.64.png'
import Powerline_256 from './Powerline.256.png'
import PowerPoleMk1_64 from './PowerPoleMk1.64.png'
import PowerPoleMk1_256 from './PowerPoleMk1.256.png'
import PowerPoleMk2_64 from './PowerPoleMk2.64.png'
import PowerPoleMk2_256 from './PowerPoleMk2.256.png'
import PowerPoleMk3_64 from './PowerPoleMk3.64.png'
import PowerPoleMk3_256 from './PowerPoleMk3.256.png'
import PowerPoleWall_MK1_64 from './PowerPoleWall_MK1.64.png'
import PowerPoleWall_MK1_256 from './PowerPoleWall_MK1.256.png'
import PowerPoleWall_MK2_64 from './PowerPoleWall_MK2.64.png'
import PowerPoleWall_MK2_256 from './PowerPoleWall_MK2.256.png'
import PowerPoleWall_MK3_64 from './PowerPoleWall_MK3.64.png'
import PowerPoleWall_MK3_256 from './PowerPoleWall_MK3.256.png'
import PowerPoleWallDouble_MK1_64 from './PowerPoleWallDouble_MK1.64.png'
import PowerPoleWallDouble_MK1_256 from './PowerPoleWallDouble_MK1.256.png'
import PowerPoleWallDouble_MK2_64 from './PowerPoleWallDouble_MK2.64.png'
import PowerPoleWallDouble_MK2_256 from './PowerPoleWallDouble_MK2.256.png'
import PowerPoleWallDouble_MK3_64 from './PowerPoleWallDouble_MK3.64.png'
import PowerPoleWallDouble_MK3_256 from './PowerPoleWallDouble_MK3.256.png'
import IconDesc_PowerStorage_64 from './IconDesc_PowerStorage.64.png'
import IconDesc_PowerStorage_256 from './IconDesc_PowerStorage.256.png'
import IconDesc_PowerSwitch_64 from './IconDesc_PowerSwitch.64.png'
import IconDesc_PowerSwitch_256 from './IconDesc_PowerSwitch.256.png'
import RadarTower_64 from './RadarTower.64.png'
import RadarTower_256 from './RadarTower.256.png'
import ResourceSink_64 from './ResourceSink.64.png'
import ResourceSink_256 from './ResourceSink.256.png'
import ResourceSinkShop_64 from './ResourceSinkShop.64.png'
import ResourceSinkShop_256 from './ResourceSinkShop.256.png'
import SmelterMk1_64 from './SmelterMk1.64.png'
import SmelterMk1_256 from './SmelterMk1.256.png'
import SpaceElevator_64 from './SpaceElevator.64.png'
import SpaceElevator_256 from './SpaceElevator.256.png'
import StorageContainer_64 from './StorageContainer.64.png'
import StorageContainer_256 from './StorageContainer.256.png'
import StorageContainerMk2_64 from './StorageContainerMk2.64.png'
import StorageContainerMk2_256 from './StorageContainerMk2.256.png'
import PlayerStorage_64 from './PlayerStorage.64.png'
import PlayerStorage_256 from './PlayerStorage.256.png'
import FluidStorage_64 from './FluidStorage.64.png'
import FluidStorage_256 from './FluidStorage.256.png'
import IconDesc_StreetLight_64 from './IconDesc_StreetLight.64.png'
import IconDesc_StreetLight_256 from './IconDesc_StreetLight.256.png'
import Hub_64 from './Hub.64.png'
import Hub_256 from './Hub.256.png'
import DockingStation_64 from './DockingStation.64.png'
import DockingStation_256 from './DockingStation.256.png'
import TrainDockingFluid_64 from './TrainDockingFluid.64.png'
import TrainDockingFluid_256 from './TrainDockingFluid.256.png'
import EmptyPlatform_64 from './EmptyPlatform.64.png'
import EmptyPlatform_256 from './EmptyPlatform.256.png'
import TrainStation_64 from './TrainStation.64.png'
import TrainStation_256 from './TrainStation.256.png'
import Image_0_White_64 from './Image_0_White.64.png'
import Image_0_White_256 from './Image_0_White.256.png'
import Track_64 from './Track.64.png'
import Track_256 from './Track.256.png'
import TruckStation_64 from './TruckStation.64.png'
import TruckStation_256 from './TruckStation.256.png'
import Waterpump_64 from './Waterpump.64.png'
import Waterpump_256 from './Waterpump.256.png'
import Workbench_64 from './Workbench.64.png'
import Workbench_256 from './Workbench.256.png'
import Workshop_64 from './Workshop.64.png'
import Workshop_256 from './Workshop.256.png'
import IconDesc_CandyCaneDecor_64 from './IconDesc_CandyCaneDecor.64.png'
import IconDesc_CandyCaneDecor_256 from './IconDesc_CandyCaneDecor.256.png'
import IconDesc_SnowDispenser_64 from './IconDesc_SnowDispenser.64.png'
import IconDesc_SnowDispenser_256 from './IconDesc_SnowDispenser.256.png'
import IconDesc_Snowman_64 from './IconDesc_Snowman.64.png'
import IconDesc_Snowman_256 from './IconDesc_Snowman.256.png'
import IconDesc_ChristmasTreeBare_64 from './IconDesc_ChristmasTreeBare.64.png'
import IconDesc_ChristmasTreeBare_256 from './IconDesc_ChristmasTreeBare.256.png'
import IconDesc_WreathNoBow_64 from './IconDesc_WreathNoBow.64.png'
import IconDesc_WreathNoBow_256 from './IconDesc_WreathNoBow.256.png'
import Berry_64 from './Berry.64.png'
import Berry_256 from './Berry.256.png'
import Mushroom_64 from './Mushroom.64.png'
import Mushroom_256 from './Mushroom.256.png'
import Nut_new_64 from './Nut_new.64.png'
import Nut_new_256 from './Nut_new.256.png'
import IconDesc_Beacon_64 from './IconDesc_Beacon.64.png'
import IconDesc_Beacon_256 from './IconDesc_Beacon.256.png'
import Parachute_64 from './Parachute.64.png'
import Parachute_256 from './Parachute.256.png'
import Inhaler_64 from './Inhaler.64.png'
import Inhaler_256 from './Inhaler.256.png'
import Award_Statue_Character_Silver_64 from './Award_Statue_Character_Silver.64.png'
import Award_Statue_Character_Silver_256 from './Award_Statue_Character_Silver.256.png'
import Award_Statue_Character_Bronze_64 from './Award_Statue_Character_Bronze.64.png'
import Award_Statue_Character_Bronze_256 from './Award_Statue_Character_Bronze.256.png'
import Award_Statue_Character_Gold_64 from './Award_Statue_Character_Gold.64.png'
import Award_Statue_Character_Gold_256 from './Award_Statue_Character_Gold.256.png'
import Award_Statue_LizardDoggo_64 from './Award_Statue_LizardDoggo.64.png'
import Award_Statue_LizardDoggo_256 from './Award_Statue_LizardDoggo.256.png'
import Award_Statue_Nut_64 from './Award_Statue_Nut.64.png'
import Award_Statue_Nut_256 from './Award_Statue_Nut.256.png'
import Award_Statue_Hog_64 from './Award_Statue_Hog.64.png'
import Award_Statue_Hog_256 from './Award_Statue_Hog.256.png'
import Award_Statue_SpaceGiraffe_64 from './Award_Statue_SpaceGiraffe.64.png'
import Award_Statue_SpaceGiraffe_256 from './Award_Statue_SpaceGiraffe.256.png'
import ColorGun_64 from './ColorGun.64.png'
import ColorGun_256 from './ColorGun.256.png'
import Chainsaw_64 from './Chainsaw.64.png'
import Chainsaw_256 from './Chainsaw.256.png'
import IconDesc_CoffeeCup_64 from './IconDesc_CoffeeCup.64.png'
import IconDesc_CoffeeCup_256 from './IconDesc_CoffeeCup.256.png'
import GasMask_64 from './GasMask.64.png'
import GasMask_256 from './GasMask.256.png'
import ObjectScanner_64 from './ObjectScanner.64.png'
import ObjectScanner_256 from './ObjectScanner.256.png'
import IconDesc_CupGold_64 from './IconDesc_CupGold.64.png'
import IconDesc_CupGold_256 from './IconDesc_CupGold.256.png'
import IconDesc_GolfCart_64 from './IconDesc_GolfCart.64.png'
import IconDesc_GolfCart_256 from './IconDesc_GolfCart.256.png'
import IconDesc_HazmatSuit_64 from './IconDesc_HazmatSuit.64.png'
import IconDesc_HazmatSuit_256 from './IconDesc_HazmatSuit.256.png'
import IconDesc_SnowballMittens_64 from './IconDesc_SnowballMittens.64.png'
import IconDesc_SnowballMittens_256 from './IconDesc_SnowballMittens.256.png'
import IconDesc_Hoverpack_64 from './IconDesc_Hoverpack.64.png'
import IconDesc_Hoverpack_256 from './IconDesc_Hoverpack.256.png'
import Jetpack_64 from './Jetpack.64.png'
import Jetpack_256 from './Jetpack.256.png'
import IconDesc_SprintingStilts_64 from './IconDesc_SprintingStilts.64.png'
import IconDesc_SprintingStilts_256 from './IconDesc_SprintingStilts.256.png'
import RebarGun_64 from './RebarGun.64.png'
import RebarGun_256 from './RebarGun.256.png'
import Detonator_64 from './Detonator.64.png'
import Detonator_256 from './Detonator.256.png'
import PortableMiner_64 from './PortableMiner.64.png'
import PortableMiner_256 from './PortableMiner.256.png'
import RifleMK1_64 from './RifleMK1.64.png'
import RifleMK1_256 from './RifleMK1.256.png'
import XenoZapper_64 from './XenoZapper.64.png'
import XenoZapper_256 from './XenoZapper.256.png'
import IconDesc_CaneEquipment_64 from './IconDesc_CaneEquipment.64.png'
import IconDesc_CaneEquipment_256 from './IconDesc_CaneEquipment.256.png'
import ShockBaton_64 from './ShockBaton.64.png'
import ShockBaton_256 from './ShockBaton.256.png'
import IconDesc_Zipline_64 from './IconDesc_Zipline.64.png'
import IconDesc_Zipline_256 from './IconDesc_Zipline.256.png'
import IconDesc_Battery_64 from './IconDesc_Battery.64.png'
import IconDesc_Battery_256 from './IconDesc_Battery.256.png'
import IconDesc_CanePart_64 from './IconDesc_CanePart.64.png'
import IconDesc_CanePart_256 from './IconDesc_CanePart.256.png'
import IconDesc_Gift_64 from './IconDesc_Gift.64.png'
import IconDesc_Gift_256 from './IconDesc_Gift.256.png'
import IconDesc_Snow_64 from './IconDesc_Snow.64.png'
import IconDesc_Snow_256 from './IconDesc_Snow.256.png'
import IconDesc_XmasBall_Red_64 from './IconDesc_XmasBall_Red.64.png'
import IconDesc_XmasBall_Red_256 from './IconDesc_XmasBall_Red.256.png'
import IconDesc_XmasBall_Blue_64 from './IconDesc_XmasBall_Blue.64.png'
import IconDesc_XmasBall_Blue_256 from './IconDesc_XmasBall_Blue.256.png'
import IconDesc_XmasBall_Yellow_64 from './IconDesc_XmasBall_Yellow.64.png'
import IconDesc_XmasBall_Yellow_256 from './IconDesc_XmasBall_Yellow.256.png'
import IconDesc_XmasBall_Silver_64 from './IconDesc_XmasBall_Silver.64.png'
import IconDesc_XmasBall_Silver_256 from './IconDesc_XmasBall_Silver.256.png'
import IconDesc_Balls_64 from './IconDesc_Balls.64.png'
import IconDesc_Balls_256 from './IconDesc_Balls.256.png'
import IconDesc_Bow_64 from './IconDesc_Bow.64.png'
import IconDesc_Bow_256 from './IconDesc_Bow.256.png'
import IconDesc_Branch_64 from './IconDesc_Branch.64.png'
import IconDesc_Branch_256 from './IconDesc_Branch.256.png'
import IconDesc_XmasLights_64 from './IconDesc_XmasLights.64.png'
import IconDesc_XmasLights_256 from './IconDesc_XmasLights.256.png'
import IconDesc_Star_64 from './IconDesc_Star.64.png'
import IconDesc_Star_256 from './IconDesc_Star.256.png'
import IconDesc_Wreath_64 from './IconDesc_Wreath.64.png'
import IconDesc_Wreath_256 from './IconDesc_Wreath.256.png'
import Wat_1_64 from './Wat_1.64.png'
import Wat_1_256 from './Wat_1.256.png'
import Wat_2_64 from './Wat_2.64.png'
import Wat_2_256 from './Wat_2.256.png'
import HardDrive_64 from './HardDrive.64.png'
import HardDrive_256 from './HardDrive.256.png'
import PowerSlugGreen_64 from './PowerSlugGreen.64.png'
import PowerSlugGreen_256 from './PowerSlugGreen.256.png'
import PowerSlugYellow_64 from './PowerSlugYellow.64.png'
import PowerSlugYellow_256 from './PowerSlugYellow.256.png'
import PowerSlugPurple_64 from './PowerSlugPurple.64.png'
import PowerSlugPurple_256 from './PowerSlugPurple.256.png'
import PowerShard_64 from './PowerShard.64.png'
import PowerShard_256 from './PowerShard.256.png'
import LiquidAlumina_Pipe_64 from './LiquidAlumina_Pipe.64.png'
import LiquidAlumina_Pipe_256 from './LiquidAlumina_Pipe.256.png'
import IconDesc_AluminiumCasing_64 from './IconDesc_AluminiumCasing.64.png'
import IconDesc_AluminiumCasing_256 from './IconDesc_AluminiumCasing.256.png'
import IconDesc_AluminiumIngot_64 from './IconDesc_AluminiumIngot.64.png'
import IconDesc_AluminiumIngot_256 from './IconDesc_AluminiumIngot.256.png'
import IconDesc_AluminiumSheet_64 from './IconDesc_AluminiumSheet.64.png'
import IconDesc_AluminiumSheet_256 from './IconDesc_AluminiumSheet.256.png'
import IconDesc_Heatsink_64 from './IconDesc_Heatsink.64.png'
import IconDesc_Heatsink_256 from './IconDesc_Heatsink.256.png'
import IconDesc_AluminiumScrap_64 from './IconDesc_AluminiumScrap.64.png'
import IconDesc_AluminiumScrap_256 from './IconDesc_AluminiumScrap.256.png'
import IconDesc_Cables_64 from './IconDesc_Cables.64.png'
import IconDesc_Cables_256 from './IconDesc_Cables.256.png'
import Rifle_Magazine_64 from './Rifle_Magazine.64.png'
import Rifle_Magazine_256 from './Rifle_Magazine.256.png'
import IconDesc_Concrete_64 from './IconDesc_Concrete.64.png'
import IconDesc_Concrete_256 from './IconDesc_Concrete.256.png'
import IconDesc_CircuitBoard_64 from './IconDesc_CircuitBoard.64.png'
import IconDesc_CircuitBoard_256 from './IconDesc_CircuitBoard.256.png'
import IconDesc_AILimiter_64 from './IconDesc_AILimiter.64.png'
import IconDesc_AILimiter_256 from './IconDesc_AILimiter.256.png'
import IconDesc_ColorCartridge_64 from './IconDesc_ColorCartridge.64.png'
import IconDesc_ColorCartridge_256 from './IconDesc_ColorCartridge.256.png'
import CompactedCoal_64 from './CompactedCoal.64.png'
import CompactedCoal_256 from './CompactedCoal.256.png'
import IconDesc_Computer_64 from './IconDesc_Computer.64.png'
import IconDesc_Computer_256 from './IconDesc_Computer.256.png'
import IconDesc_QuantumComputer_64 from './IconDesc_QuantumComputer.64.png'
import IconDesc_QuantumComputer_256 from './IconDesc_QuantumComputer.256.png'
import IconDesc_SuperComputer_64 from './IconDesc_SuperComputer.64.png'
import IconDesc_SuperComputer_256 from './IconDesc_SuperComputer.256.png'
import IconDesc_CoolingSystem_64 from './IconDesc_CoolingSystem.64.png'
import IconDesc_CoolingSystem_256 from './IconDesc_CoolingSystem.256.png'
import IconDesc_CopperDust_64 from './IconDesc_CopperDust.64.png'
import IconDesc_CopperDust_256 from './IconDesc_CopperDust.256.png'
import IconDesc_CopperIngot_64 from './IconDesc_CopperIngot.64.png'
import IconDesc_CopperIngot_256 from './IconDesc_CopperIngot.256.png'
import IconDesc_CopperSheet_64 from './IconDesc_CopperSheet.64.png'
import IconDesc_CopperSheet_256 from './IconDesc_CopperSheet.256.png'
import IconDesc_CrystalOscillator_64 from './IconDesc_CrystalOscillator.64.png'
import IconDesc_CrystalOscillator_256 from './IconDesc_CrystalOscillator.256.png'
import IconDesc_DarkMatter_64 from './IconDesc_DarkMatter.64.png'
import IconDesc_DarkMatter_256 from './IconDesc_DarkMatter.256.png'
import IconDesc_ElectromagneticControlRod_64 from './IconDesc_ElectromagneticControlRod.64.png'
import IconDesc_ElectromagneticControlRod_256 from './IconDesc_ElectromagneticControlRod.256.png'
import IconDesc_GasMaskFilter_64 from './IconDesc_GasMaskFilter.64.png'
import IconDesc_GasMaskFilter_256 from './IconDesc_GasMaskFilter.256.png'
import IconDesc_EmptyCannister_64 from './IconDesc_EmptyCannister.64.png'
import IconDesc_EmptyCannister_256 from './IconDesc_EmptyCannister.256.png'
import IconDesc_Fuel_64 from './IconDesc_Fuel.64.png'
import IconDesc_Fuel_256 from './IconDesc_Fuel.256.png'
import IconDesc_LiquidFuel_Pipe_64 from './IconDesc_LiquidFuel_Pipe.64.png'
import IconDesc_LiquidFuel_Pipe_256 from './IconDesc_LiquidFuel_Pipe.256.png'
import IconDesc_PressureTank_64 from './IconDesc_PressureTank.64.png'
import IconDesc_PressureTank_256 from './IconDesc_PressureTank.256.png'
import IconDesc_CateriumIngot_64 from './IconDesc_CateriumIngot.64.png'
import IconDesc_CateriumIngot_256 from './IconDesc_CateriumIngot.256.png'
import IconDesc_Gunpowder_64 from './IconDesc_Gunpowder.64.png'
import IconDesc_Gunpowder_256 from './IconDesc_Gunpowder.256.png'
import IconDesc_LiquidHeavyOilResidue_Pipe_64 from './IconDesc_LiquidHeavyOilResidue_Pipe.64.png'
import IconDesc_LiquidHeavyOilResidue_Pipe_256 from './IconDesc_LiquidHeavyOilResidue_Pipe.256.png'
import OilResidue_64 from './OilResidue.64.png'
import OilResidue_256 from './OilResidue.256.png'
import IconDesc_HighSpeedConnector_64 from './IconDesc_HighSpeedConnector.64.png'
import IconDesc_HighSpeedConnector_256 from './IconDesc_HighSpeedConnector.256.png'
import IconDesc_Quickwire_64 from './IconDesc_Quickwire.64.png'
import IconDesc_Quickwire_256 from './IconDesc_Quickwire.256.png'
import IconDesc_HubParts_64 from './IconDesc_HubParts.64.png'
import IconDesc_HubParts_256 from './IconDesc_HubParts.256.png'
import IconDesc_LiquidSulfuricAcid_Pipe_64 from './IconDesc_LiquidSulfuricAcid_Pipe.64.png'
import IconDesc_LiquidSulfuricAcid_Pipe_256 from './IconDesc_LiquidSulfuricAcid_Pipe.256.png'
import IconDesc_HazmatFilter_64 from './IconDesc_HazmatFilter.64.png'
import IconDesc_HazmatFilter_256 from './IconDesc_HazmatFilter.256.png'
import IconDesc_IronIngot_64 from './IconDesc_IronIngot.64.png'
import IconDesc_IronIngot_256 from './IconDesc_IronIngot.256.png'
import IconDesc_IronPlates_64 from './IconDesc_IronPlates.64.png'
import IconDesc_IronPlates_256 from './IconDesc_IronPlates.256.png'
import IconDesc_ReinforcedIronPlates_64 from './IconDesc_ReinforcedIronPlates.64.png'
import IconDesc_ReinforcedIronPlates_256 from './IconDesc_ReinforcedIronPlates.256.png'
import IconDesc_IronRods_64 from './IconDesc_IronRods.64.png'
import IconDesc_IronRods_256 from './IconDesc_IronRods.256.png'
import IconDesc_IronScrews_64 from './IconDesc_IronScrews.64.png'
import IconDesc_IronScrews_256 from './IconDesc_IronScrews.256.png'
import IconDesc_ModularFrame_64 from './IconDesc_ModularFrame.64.png'
import IconDesc_ModularFrame_256 from './IconDesc_ModularFrame.256.png'
import IconDesc_FusedModularFrame_64 from './IconDesc_FusedModularFrame.64.png'
import IconDesc_FusedModularFrame_256 from './IconDesc_FusedModularFrame.256.png'
import IconDesc_ModularFrameHeavy_64 from './IconDesc_ModularFrameHeavy.64.png'
import IconDesc_ModularFrameHeavy_256 from './IconDesc_ModularFrameHeavy.256.png'
import IconDesc_RadioControlUnit_64 from './IconDesc_RadioControlUnit.64.png'
import IconDesc_RadioControlUnit_256 from './IconDesc_RadioControlUnit.256.png'
import IconDesc_Engine_64 from './IconDesc_Engine.64.png'
import IconDesc_Engine_256 from './IconDesc_Engine.256.png'
import IconDesc_TurboMotor_64 from './IconDesc_TurboMotor.64.png'
import IconDesc_TurboMotor_256 from './IconDesc_TurboMotor.256.png'
import IconDesc_NitricAcid_64 from './IconDesc_NitricAcid.64.png'
import IconDesc_NitricAcid_256 from './IconDesc_NitricAcid.256.png'
import IconDesc_Explosive_64 from './IconDesc_Explosive.64.png'
import IconDesc_Explosive_256 from './IconDesc_Explosive.256.png'
import IconDesc_NonFissileUranium_64 from './IconDesc_NonFissileUranium.64.png'
import IconDesc_NonFissileUranium_256 from './IconDesc_NonFissileUranium.256.png'
import IconDesc_NuclearWaste_64 from './IconDesc_NuclearWaste.64.png'
import IconDesc_NuclearWaste_256 from './IconDesc_NuclearWaste.256.png'
import IconDesc_PlutoniumWaste_64 from './IconDesc_PlutoniumWaste.64.png'
import IconDesc_PlutoniumWaste_256 from './IconDesc_PlutoniumWaste.256.png'
import IconDesc_PetroleumCoke_64 from './IconDesc_PetroleumCoke.64.png'
import IconDesc_PetroleumCoke_256 from './IconDesc_PetroleumCoke.256.png'
import IconDesc_Plastic_64 from './IconDesc_Plastic.64.png'
import IconDesc_Plastic_256 from './IconDesc_Plastic.256.png'
import IconDesc_EncasedPlutoniumCell_64 from './IconDesc_EncasedPlutoniumCell.64.png'
import IconDesc_EncasedPlutoniumCell_256 from './IconDesc_EncasedPlutoniumCell.256.png'
import IconDesc_PlutoniumPellet_64 from './IconDesc_PlutoniumPellet.64.png'
import IconDesc_PlutoniumPellet_256 from './IconDesc_PlutoniumPellet.256.png'
import IconDesc_PolymerResin_64 from './IconDesc_PolymerResin.64.png'
import IconDesc_PolymerResin_256 from './IconDesc_PolymerResin.256.png'
import IconDesc_ConversionCube_64 from './IconDesc_ConversionCube.64.png'
import IconDesc_ConversionCube_256 from './IconDesc_ConversionCube.256.png'
import IconDesc_QuantumCrystal_64 from './IconDesc_QuantumCrystal.64.png'
import IconDesc_QuantumCrystal_256 from './IconDesc_QuantumCrystal.256.png'
import IconDesc_SuperPositionOscillator_64 from './IconDesc_SuperPositionOscillator.64.png'
import IconDesc_SuperPositionOscillator_256 from './IconDesc_SuperPositionOscillator.256.png'
import IconDesc_QuartzResource_64 from './IconDesc_QuartzResource.64.png'
import IconDesc_QuartzResource_256 from './IconDesc_QuartzResource.256.png'
import IconDesc_Ficsit_Coupon_64 from './IconDesc_Ficsit_Coupon.64.png'
import IconDesc_Ficsit_Coupon_256 from './IconDesc_Ficsit_Coupon.256.png'
import IconDesc_Rotor_64 from './IconDesc_Rotor.64.png'
import IconDesc_Rotor_256 from './IconDesc_Rotor.256.png'
import IconDesc_Rubber_64 from './IconDesc_Rubber.64.png'
import IconDesc_Rubber_256 from './IconDesc_Rubber.256.png'
import IconDesc_Silica_64 from './IconDesc_Silica.64.png'
import IconDesc_Silica_256 from './IconDesc_Silica.256.png'
import IconDesc_SnowballProjectile_64 from './IconDesc_SnowballProjectile.64.png'
import IconDesc_SnowballProjectile_256 from './IconDesc_SnowballProjectile.256.png'
import Lock_Icon_64 from './Lock_Icon.64.png'
import Lock_Icon_256 from './Lock_Icon.256.png'
import IconDesc_SpelevatorPart_1_64 from './IconDesc_SpelevatorPart_1.64.png'
import IconDesc_SpelevatorPart_1_256 from './IconDesc_SpelevatorPart_1.256.png'
import IconDesc_SpelevatorPart_2_64 from './IconDesc_SpelevatorPart_2.64.png'
import IconDesc_SpelevatorPart_2_256 from './IconDesc_SpelevatorPart_2.256.png'
import SpelevatorPart_3_64 from './SpelevatorPart_3.64.png'
import SpelevatorPart_3_256 from './SpelevatorPart_3.256.png'
import IconDesc_SpelevatorPart_4_64 from './IconDesc_SpelevatorPart_4.64.png'
import IconDesc_SpelevatorPart_4_256 from './IconDesc_SpelevatorPart_4.256.png'
import IconDesc_SpelevatorPart_5_64 from './IconDesc_SpelevatorPart_5.64.png'
import IconDesc_SpelevatorPart_5_256 from './IconDesc_SpelevatorPart_5.256.png'
import IconDesc_MagneticFieldGenerator_64 from './IconDesc_MagneticFieldGenerator.64.png'
import IconDesc_MagneticFieldGenerator_256 from './IconDesc_MagneticFieldGenerator.256.png'
import IconDesc_AssemblyDirectorSystem_64 from './IconDesc_AssemblyDirectorSystem.64.png'
import IconDesc_AssemblyDirectorSystem_256 from './IconDesc_AssemblyDirectorSystem.256.png'
import IconDesc_ThermalPropulsionRocket_64 from './IconDesc_ThermalPropulsionRocket.64.png'
import IconDesc_ThermalPropulsionRocket_256 from './IconDesc_ThermalPropulsionRocket.256.png'
import IconDesc_NuclearPasta_64 from './IconDesc_NuclearPasta.64.png'
import IconDesc_NuclearPasta_256 from './IconDesc_NuclearPasta.256.png'
import IconDesc_SpikedRebar_64 from './IconDesc_SpikedRebar.64.png'
import IconDesc_SpikedRebar_256 from './IconDesc_SpikedRebar.256.png'
import IconDesc_Stator_64 from './IconDesc_Stator.64.png'
import IconDesc_Stator_256 from './IconDesc_Stator.256.png'
import IconDesc_SteelIngot_64 from './IconDesc_SteelIngot.64.png'
import IconDesc_SteelIngot_256 from './IconDesc_SteelIngot.256.png'
import IconDesc_SteelPipe_64 from './IconDesc_SteelPipe.64.png'
import IconDesc_SteelPipe_256 from './IconDesc_SteelPipe.256.png'
import IconDesc_SteelBeam_64 from './IconDesc_SteelBeam.64.png'
import IconDesc_SteelBeam_256 from './IconDesc_SteelBeam.256.png'
import IconDesc_EncasedSteelBeam_64 from './IconDesc_EncasedSteelBeam.64.png'
import IconDesc_EncasedSteelBeam_256 from './IconDesc_EncasedSteelBeam.256.png'
import IconDesc_LiquidTurboFuel_Pipe_64 from './IconDesc_LiquidTurboFuel_Pipe.64.png'
import IconDesc_LiquidTurboFuel_Pipe_256 from './IconDesc_LiquidTurboFuel_Pipe.256.png'
import IconDesc_TurboFuel_64 from './IconDesc_TurboFuel.64.png'
import IconDesc_TurboFuel_256 from './IconDesc_TurboFuel.256.png'
import IconDesc_NuclearCell_64 from './IconDesc_NuclearCell.64.png'
import IconDesc_NuclearCell_256 from './IconDesc_NuclearCell.256.png'
import IconDesc_UraniumPellets_64 from './IconDesc_UraniumPellets.64.png'
import IconDesc_UraniumPellets_256 from './IconDesc_UraniumPellets.256.png'
import IconDesc_Wire_64 from './IconDesc_Wire.64.png'
import IconDesc_Wire_256 from './IconDesc_Wire.256.png'
import Oil_64 from './Oil.64.png'
import Oil_256 from './Oil.256.png'
import IconDesc_PackagedWater_64 from './IconDesc_PackagedWater.64.png'
import IconDesc_PackagedWater_256 from './IconDesc_PackagedWater.256.png'
import IconDesc_PackagedAluminaSolution_64 from './IconDesc_PackagedAluminaSolution.64.png'
import IconDesc_PackagedAluminaSolution_256 from './IconDesc_PackagedAluminaSolution.256.png'
import IconDesc_HogPart_64 from './IconDesc_HogPart.64.png'
import IconDesc_HogPart_256 from './IconDesc_HogPart.256.png'
import IconDesc_SpitterPart_64 from './IconDesc_SpitterPart.64.png'
import IconDesc_SpitterPart_256 from './IconDesc_SpitterPart.256.png'
import IconDesc_SolidBiofuel_64 from './IconDesc_SolidBiofuel.64.png'
import IconDesc_SolidBiofuel_256 from './IconDesc_SolidBiofuel.256.png'
import IconDesc_LiquidBiofuel_Pipe_64 from './IconDesc_LiquidBiofuel_Pipe.64.png'
import IconDesc_LiquidBiofuel_Pipe_256 from './IconDesc_LiquidBiofuel_Pipe.256.png'
import IconDesc_LiquidBiofuel_64 from './IconDesc_LiquidBiofuel.64.png'
import IconDesc_LiquidBiofuel_256 from './IconDesc_LiquidBiofuel.256.png'
import IconDesc_Fabric_64 from './IconDesc_Fabric.64.png'
import IconDesc_Fabric_256 from './IconDesc_Fabric.256.png'
import FlowerPetals_Final_64 from './FlowerPetals_Final.64.png'
import FlowerPetals_Final_256 from './FlowerPetals_Final.256.png'
import IconDesc_Biomass_Final_64 from './IconDesc_Biomass_Final.64.png'
import IconDesc_Biomass_Final_256 from './IconDesc_Biomass_Final.256.png'
import IconDesc_Leaves_64 from './IconDesc_Leaves.64.png'
import IconDesc_Leaves_256 from './IconDesc_Leaves.256.png'
import IconDesc_Mycelia_64 from './IconDesc_Mycelia.64.png'
import IconDesc_Mycelia_256 from './IconDesc_Mycelia.256.png'
import IconDesc_Vines_64 from './IconDesc_Vines.64.png'
import IconDesc_Vines_256 from './IconDesc_Vines.256.png'
import IconDesc_Wood_64 from './IconDesc_Wood.64.png'
import IconDesc_Wood_256 from './IconDesc_Wood.256.png'
import IconDesc_PackagedNitricAcid_64 from './IconDesc_PackagedNitricAcid.64.png'
import IconDesc_PackagedNitricAcid_256 from './IconDesc_PackagedNitricAcid.256.png'
import IconDesc_PackagedNitrogen_64 from './IconDesc_PackagedNitrogen.64.png'
import IconDesc_PackagedNitrogen_256 from './IconDesc_PackagedNitrogen.256.png'
import IconDesc_PckagedSulphuricAcid_64 from './IconDesc_PckagedSulphuricAcid.64.png'
import IconDesc_PckagedSulphuricAcid_256 from './IconDesc_PckagedSulphuricAcid.256.png'
import IconDesc_NuclearFuelRod_64 from './IconDesc_NuclearFuelRod.64.png'
import IconDesc_NuclearFuelRod_256 from './IconDesc_NuclearFuelRod.256.png'
import IconDesc_PlutoniumFuelRod_64 from './IconDesc_PlutoniumFuelRod.64.png'
import IconDesc_PlutoniumFuelRod_256 from './IconDesc_PlutoniumFuelRod.256.png'
import SortRule_None_64 from './SortRule_None.64.png'
import SortRule_None_256 from './SortRule_None.256.png'
import TXUI_SortRule_Overflow_64 from './TXUI_SortRule_Overflow.64.png'
import TXUI_SortRule_Overflow_256 from './TXUI_SortRule_Overflow.256.png'
import IconDesc_ConveyorPole_64 from './IconDesc_ConveyorPole.64.png'
import IconDesc_ConveyorPole_256 from './IconDesc_ConveyorPole.256.png'
import ConveyorPoleMulti_64 from './ConveyorPoleMulti.64.png'
import ConveyorPoleMulti_256 from './ConveyorPoleMulti.256.png'
import HyperTubePole_64 from './HyperTubePole.64.png'
import HyperTubePole_256 from './HyperTubePole.256.png'
import IconDesc_HyperTubeStackable_64 from './IconDesc_HyperTubeStackable.64.png'
import IconDesc_HyperTubeStackable_256 from './IconDesc_HyperTubeStackable.256.png'
import PipePole_64 from './PipePole.64.png'
import PipePole_256 from './PipePole.256.png'
import PipePole_Stackable_64 from './PipePole_Stackable.64.png'
import PipePole_Stackable_256 from './PipePole_Stackable.256.png'
import CoalOre_64 from './CoalOre.64.png'
import CoalOre_256 from './CoalOre.256.png'
import LiquidOil_Pipe_64 from './LiquidOil_Pipe.64.png'
import LiquidOil_Pipe_256 from './LiquidOil_Pipe.256.png'
import Bauxite_64 from './Bauxite.64.png'
import Bauxite_256 from './Bauxite.256.png'
import IconDesc_copper_new_64 from './IconDesc_copper_new.64.png'
import IconDesc_copper_new_256 from './IconDesc_copper_new.256.png'
import CateriumOre_64 from './CateriumOre.64.png'
import CateriumOre_256 from './CateriumOre.256.png'
import IconDesc_iron_new_64 from './IconDesc_iron_new.64.png'
import IconDesc_iron_new_256 from './IconDesc_iron_new.256.png'
import UraniumOre_64 from './UraniumOre.64.png'
import UraniumOre_256 from './UraniumOre.256.png'
import IconDesc_QuartzCrystal_64 from './IconDesc_QuartzCrystal.64.png'
import IconDesc_QuartzCrystal_256 from './IconDesc_QuartzCrystal.256.png'
import SAMOre_64 from './SAMOre.64.png'
import SAMOre_256 from './SAMOre.256.png'
import Stone_64 from './Stone.64.png'
import Stone_256 from './Stone.256.png'
import Sulfur_64 from './Sulfur.64.png'
import Sulfur_256 from './Sulfur.256.png'
import LiquidWater_Pipe_64 from './LiquidWater_Pipe.64.png'
import LiquidWater_Pipe_256 from './LiquidWater_Pipe.256.png'
import IconDesc_Geyser_128_64 from './IconDesc_Geyser_128.64.png'
import IconDesc_Geyser_128_256 from './IconDesc_Geyser_128.256.png'
import IconDesc_Geyser_64 from './IconDesc_Geyser.64.png'
import IconDesc_Geyser_256 from './IconDesc_Geyser.256.png'
import IconDesc_Drone_64 from './IconDesc_Drone.64.png'
import IconDesc_Drone_256 from './IconDesc_Drone.256.png'
import Cyberwagon_64 from './Cyberwagon.64.png'
import Cyberwagon_256 from './Cyberwagon.256.png'
import Explorer_64 from './Explorer.64.png'
import Explorer_256 from './Explorer.256.png'
import Tractor_64 from './Tractor.64.png'
import Tractor_256 from './Tractor.256.png'
import Locomotive_64 from './Locomotive.64.png'
import Locomotive_256 from './Locomotive.256.png'
import FreightCar_64 from './FreightCar.64.png'
import FreightCar_256 from './FreightCar.256.png'
import Truck_64 from './Truck.64.png'
import Truck_256 from './Truck.256.png'
import Reset_Icon_64 from './Reset_Icon.64.png'
import Reset_Icon_256 from './Reset_Icon.256.png'

export const manifest = [
	"TXUI_SortRule_AnyUndefined.64.png",
	"TXUI_SortRule_AnyUndefined.256.png",
	"Fences.64.png",
	"Fences.256.png",
	"Foundations_8x1.64.png",
	"Foundations_8x1.256.png",
	"Foundations_8x2.64.png",
	"Foundations_8x2.256.png",
	"Foundations_8x4.64.png",
	"Foundations_8x4.256.png",
	"FrameworkFoundation.64.png",
	"FrameworkFoundation.256.png",
	"Foundation_Glass.64.png",
	"Foundation_Glass.256.png",
	"Pillar_Bottom.64.png",
	"Pillar_Bottom.256.png",
	"Pillar_Middle.64.png",
	"Pillar_Middle.256.png",
	"Pillar_Top.64.png",
	"Pillar_Top.256.png",
	"QuarterPipe_01.64.png",
	"QuarterPipe_01.256.png",
	"QuarterPipe_02.64.png",
	"QuarterPipe_02.256.png",
	"QuarterPipe_Corner_01.64.png",
	"QuarterPipe_Corner_01.256.png",
	"QuarterPipe_Corner_02.64.png",
	"QuarterPipe_Corner_02.256.png",
	"IconDesc_Cnr_QuarterPipe_03.64.png",
	"IconDesc_Cnr_QuarterPipe_03.256.png",
	"IconDesc_Cnr_QuarterPipe_04.64.png",
	"IconDesc_Cnr_QuarterPipe_04.256.png",
	"IconDesc_Ladder.64.png",
	"IconDesc_Ladder.256.png",
	"Ramp8x1.64.png",
	"Ramp8x1.256.png",
	"Ramps_small.64.png",
	"Ramps_small.256.png",
	"Ramps.64.png",
	"Ramps.256.png",
	"Ramp_8x4_Inverted.64.png",
	"Ramp_8x4_Inverted.256.png",
	"Ramp_8x8x4.64.png",
	"Ramp_8x8x4.256.png",
	"TXUI_Foundation_Corner_8x1.64.png",
	"TXUI_Foundation_Corner_8x1.256.png",
	"TXUI_Ramp_Corner_8x1.64.png",
	"TXUI_Ramp_Corner_8x1.256.png",
	"TXUI_Foundation_Corner_8x2.64.png",
	"TXUI_Foundation_Corner_8x2.256.png",
	"TXUI_Ramp_Corner_8x2.64.png",
	"TXUI_Ramp_Corner_8x2.256.png",
	"TXUI_Foundation_Corner_8x4.64.png",
	"TXUI_Foundation_Corner_8x4.256.png",
	"TXUI_Ramp_Corner_8x4.64.png",
	"TXUI_Ramp_Corner_8x4.256.png",
	"IconDesc_Dbl_Ramp_8x8x2_01.64.png",
	"IconDesc_Dbl_Ramp_8x8x2_01.256.png",
	"IconDesc_Dbl_Ramp_8x8x1_01.64.png",
	"IconDesc_Dbl_Ramp_8x8x1_01.256.png",
	"IconDesc_RampInverted_8x8x1.64.png",
	"IconDesc_RampInverted_8x8x1.256.png",
	"IconDesc_C_Inv_Ramp_8x1_01.64.png",
	"IconDesc_C_Inv_Ramp_8x1_01.256.png",
	"IconDesc_C_Inv_Ramp_8x1_02.64.png",
	"IconDesc_C_Inv_Ramp_8x1_02.256.png",
	"IconDesc_RampInverted_8x8x2.64.png",
	"IconDesc_RampInverted_8x8x2.256.png",
	"IconDesc_C_Inv_Ramp_8x2_01.64.png",
	"IconDesc_C_Inv_Ramp_8x2_01.256.png",
	"IconDesc_C_Inv_Ramp_8x2_02.64.png",
	"IconDesc_C_Inv_Ramp_8x2_02.256.png",
	"IconDesc_C_Inv_Ramp_8x4_01.64.png",
	"IconDesc_C_Inv_Ramp_8x4_01.256.png",
	"IconDesc_C_Inv_Ramp_8x3_02.64.png",
	"IconDesc_C_Inv_Ramp_8x3_02.256.png",
	"T_Stair_1b.64.png",
	"T_Stair_1b.256.png",
	"StairLeft.64.png",
	"StairLeft.256.png",
	"StairRight.64.png",
	"StairRight.256.png",
	"WalkwayCross.64.png",
	"WalkwayCross.256.png",
	"WalkwayRamp.64.png",
	"WalkwayRamp.256.png",
	"WalkwayStraight.64.png",
	"WalkwayStraight.256.png",
	"WalkwayTCross.64.png",
	"WalkwayTCross.256.png",
	"WalkwayTurn.64.png",
	"WalkwayTurn.256.png",
	"Wall_Orange.64.png",
	"Wall_Orange.256.png",
	"Wall_Grey.64.png",
	"Wall_Grey.256.png",
	"Wall_Conveyor_x3_Orange.64.png",
	"Wall_Conveyor_x3_Orange.256.png",
	"Wall_Conveyor_x3_Grey.64.png",
	"Wall_Conveyor_x3_Grey.256.png",
	"Wall_Conveyor_x2_Orange.64.png",
	"Wall_Conveyor_x2_Orange.256.png",
	"Wall_Conveyor_x2_Grey.64.png",
	"Wall_Conveyor_x2_Grey.256.png",
	"Wall_Conveyor_x1_Orange.64.png",
	"Wall_Conveyor_x1_Orange.256.png",
	"Wall_Conveyor_x1_Grey.64.png",
	"Wall_Conveyor_x1_Grey.256.png",
	"Wall_Conveyor_Perpendicular_Orange.64.png",
	"Wall_Conveyor_Perpendicular_Orange.256.png",
	"Wall_Conveyor_Perpendicular_Grey.64.png",
	"Wall_Conveyor_Perpendicular_Grey.256.png",
	"Wall_Door_Center_Orange.64.png",
	"Wall_Door_Center_Orange.256.png",
	"Wall_Door_Center_Grey.64.png",
	"Wall_Door_Center_Grey.256.png",
	"Wall_Door_Left_Orange.64.png",
	"Wall_Door_Left_Orange.256.png",
	"Wall_Door_Left_Grey.64.png",
	"Wall_Door_Left_Grey.256.png",
	"Wall_Door_Right_Orange.64.png",
	"Wall_Door_Right_Orange.256.png",
	"Wall_Door_Right_Grey.64.png",
	"Wall_Door_Right_Grey.256.png",
	"Wall_Orange_Gate.64.png",
	"Wall_Orange_Gate.256.png",
	"Wall_Window_8x4_01.64.png",
	"Wall_Window_8x4_01.256.png",
	"Wall_Window_8x4_02.64.png",
	"Wall_Window_8x4_02.256.png",
	"Wall_Window_8x4_03.64.png",
	"Wall_Window_8x4_03.256.png",
	"Wall_Window_8x4_04.64.png",
	"Wall_Window_8x4_04.256.png",
	"AssemblerMk1.64.png",
	"AssemblerMk1.256.png",
	"IconDesc_Blender.64.png",
	"IconDesc_Blender.256.png",
	"ConveyorMerger.64.png",
	"ConveyorMerger.256.png",
	"ConveyorSplitter.64.png",
	"ConveyorSplitter.256.png",
	"ProgrammableSplitter.64.png",
	"ProgrammableSplitter.256.png",
	"SmartSplitter.64.png",
	"SmartSplitter.256.png",
	"IconDesc_CeilingLight.64.png",
	"IconDesc_CeilingLight.256.png",
	"ConstructorMk1.64.png",
	"ConstructorMk1.256.png",
	"ConveyorMk1.64.png",
	"ConveyorMk1.256.png",
	"ConveyorMk2.64.png",
	"ConveyorMk2.256.png",
	"ConveyorMk3.64.png",
	"ConveyorMk3.256.png",
	"ConveyorMk4.64.png",
	"ConveyorMk4.256.png",
	"ConveyorMk5.64.png",
	"ConveyorMk5.256.png",
	"ConveyorLiftMK1.64.png",
	"ConveyorLiftMK1.256.png",
	"ConveyorLiftMK2.64.png",
	"ConveyorLiftMK2.256.png",
	"ConveyorLiftMK3.64.png",
	"ConveyorLiftMK3.256.png",
	"ConveyorLiftMK4.64.png",
	"ConveyorLiftMK4.256.png",
	"ConveyorLiftMK5.64.png",
	"ConveyorLiftMK5.256.png",
	"ConveyorPole_Wall.64.png",
	"ConveyorPole_Wall.256.png",
	"IconDesc_DronePort.64.png",
	"IconDesc_DronePort.256.png",
	"IconDesc_FloodLight.64.png",
	"IconDesc_FloodLight.256.png",
	"IconDesc_FloodlightWall.64.png",
	"IconDesc_FloodlightWall.256.png",
	"Foundry.64.png",
	"Foundry.256.png",
	"IconDesc_Extractor.64.png",
	"IconDesc_Extractor.256.png",
	"IconDesc_Smasher.64.png",
	"IconDesc_Smasher.256.png",
	"BiomassGenerator.64.png",
	"BiomassGenerator.256.png",
	"CoalGenerator.64.png",
	"CoalGenerator.256.png",
	"FuelGenerator.64.png",
	"FuelGenerator.256.png",
	"GeoThermalPowerGenerator.64.png",
	"GeoThermalPowerGenerator.256.png",
	"NuclearPowerplant.64.png",
	"NuclearPowerplant.256.png",
	"IconDesc_HadronCollider.64.png",
	"IconDesc_HadronCollider.256.png",
	"IconDesc_ChristmasTree.64.png",
	"IconDesc_ChristmasTree.256.png",
	"IconDesc_HyperTube_WallHole.64.png",
	"IconDesc_HyperTube_WallHole.256.png",
	"IconDesc_HyperTube_WallSupport.64.png",
	"IconDesc_HyperTube_WallSupport.256.png",
	"FluidStorageIndustrial.64.png",
	"FluidStorageIndustrial.256.png",
	"IconDesc_JumpPad.64.png",
	"IconDesc_JumpPad.256.png",
	"LandingPad.64.png",
	"LandingPad.256.png",
	"IconDesc_LightsControlPanel.64.png",
	"IconDesc_LightsControlPanel.256.png",
	"LookOutTower.64.png",
	"LookOutTower.256.png",
	"MAM.64.png",
	"MAM.256.png",
	"Manufacturer.64.png",
	"Manufacturer.256.png",
	"MinerMk1.64.png",
	"MinerMk1.256.png",
	"MinerMk2.64.png",
	"MinerMk2.256.png",
	"MinerMk3.64.png",
	"MinerMk3.256.png",
	"OilPump.64.png",
	"OilPump.256.png",
	"IconDesc_OilRefinery.64.png",
	"IconDesc_OilRefinery.256.png",
	"IconDesc_Packager.64.png",
	"IconDesc_Packager.256.png",
	"HyperTube.64.png",
	"HyperTube.256.png",
	"HyperTubeStart.64.png",
	"HyperTubeStart.256.png",
	"PipelineJunction.64.png",
	"PipelineJunction.256.png",
	"IconDesc_Pipes.64.png",
	"IconDesc_Pipes.256.png",
	"IconDesc_PipeMK2.64.png",
	"IconDesc_PipeMK2.256.png",
	"PipeSupportWall.64.png",
	"PipeSupportWall.256.png",
	"PipeSupportWallHole.64.png",
	"PipeSupportWallHole.256.png",
	"PipePump.64.png",
	"PipePump.256.png",
	"IconDesc_PipePumpMK2.64.png",
	"IconDesc_PipePumpMK2.256.png",
	"IconDesc_Valve.64.png",
	"IconDesc_Valve.256.png",
	"Powerline.64.png",
	"Powerline.256.png",
	"PowerPoleMk1.64.png",
	"PowerPoleMk1.256.png",
	"PowerPoleMk2.64.png",
	"PowerPoleMk2.256.png",
	"PowerPoleMk3.64.png",
	"PowerPoleMk3.256.png",
	"PowerPoleWall_MK1.64.png",
	"PowerPoleWall_MK1.256.png",
	"PowerPoleWall_MK2.64.png",
	"PowerPoleWall_MK2.256.png",
	"PowerPoleWall_MK3.64.png",
	"PowerPoleWall_MK3.256.png",
	"PowerPoleWallDouble_MK1.64.png",
	"PowerPoleWallDouble_MK1.256.png",
	"PowerPoleWallDouble_MK2.64.png",
	"PowerPoleWallDouble_MK2.256.png",
	"PowerPoleWallDouble_MK3.64.png",
	"PowerPoleWallDouble_MK3.256.png",
	"IconDesc_PowerStorage.64.png",
	"IconDesc_PowerStorage.256.png",
	"IconDesc_PowerSwitch.64.png",
	"IconDesc_PowerSwitch.256.png",
	"RadarTower.64.png",
	"RadarTower.256.png",
	"ResourceSink.64.png",
	"ResourceSink.256.png",
	"ResourceSinkShop.64.png",
	"ResourceSinkShop.256.png",
	"SmelterMk1.64.png",
	"SmelterMk1.256.png",
	"SpaceElevator.64.png",
	"SpaceElevator.256.png",
	"StorageContainer.64.png",
	"StorageContainer.256.png",
	"StorageContainerMk2.64.png",
	"StorageContainerMk2.256.png",
	"PlayerStorage.64.png",
	"PlayerStorage.256.png",
	"FluidStorage.64.png",
	"FluidStorage.256.png",
	"IconDesc_StreetLight.64.png",
	"IconDesc_StreetLight.256.png",
	"Hub.64.png",
	"Hub.256.png",
	"DockingStation.64.png",
	"DockingStation.256.png",
	"TrainDockingFluid.64.png",
	"TrainDockingFluid.256.png",
	"EmptyPlatform.64.png",
	"EmptyPlatform.256.png",
	"TrainStation.64.png",
	"TrainStation.256.png",
	"Image_0_White.64.png",
	"Image_0_White.256.png",
	"Track.64.png",
	"Track.256.png",
	"TruckStation.64.png",
	"TruckStation.256.png",
	"Waterpump.64.png",
	"Waterpump.256.png",
	"Workbench.64.png",
	"Workbench.256.png",
	"Workshop.64.png",
	"Workshop.256.png",
	"IconDesc_CandyCaneDecor.64.png",
	"IconDesc_CandyCaneDecor.256.png",
	"IconDesc_SnowDispenser.64.png",
	"IconDesc_SnowDispenser.256.png",
	"IconDesc_Snowman.64.png",
	"IconDesc_Snowman.256.png",
	"IconDesc_ChristmasTreeBare.64.png",
	"IconDesc_ChristmasTreeBare.256.png",
	"IconDesc_WreathNoBow.64.png",
	"IconDesc_WreathNoBow.256.png",
	"Berry.64.png",
	"Berry.256.png",
	"Mushroom.64.png",
	"Mushroom.256.png",
	"Nut_new.64.png",
	"Nut_new.256.png",
	"IconDesc_Beacon.64.png",
	"IconDesc_Beacon.256.png",
	"Parachute.64.png",
	"Parachute.256.png",
	"Inhaler.64.png",
	"Inhaler.256.png",
	"Award_Statue_Character_Silver.64.png",
	"Award_Statue_Character_Silver.256.png",
	"Award_Statue_Character_Bronze.64.png",
	"Award_Statue_Character_Bronze.256.png",
	"Award_Statue_Character_Gold.64.png",
	"Award_Statue_Character_Gold.256.png",
	"Award_Statue_LizardDoggo.64.png",
	"Award_Statue_LizardDoggo.256.png",
	"Award_Statue_Nut.64.png",
	"Award_Statue_Nut.256.png",
	"Award_Statue_Hog.64.png",
	"Award_Statue_Hog.256.png",
	"Award_Statue_SpaceGiraffe.64.png",
	"Award_Statue_SpaceGiraffe.256.png",
	"ColorGun.64.png",
	"ColorGun.256.png",
	"Chainsaw.64.png",
	"Chainsaw.256.png",
	"IconDesc_CoffeeCup.64.png",
	"IconDesc_CoffeeCup.256.png",
	"GasMask.64.png",
	"GasMask.256.png",
	"ObjectScanner.64.png",
	"ObjectScanner.256.png",
	"IconDesc_CupGold.64.png",
	"IconDesc_CupGold.256.png",
	"IconDesc_GolfCart.64.png",
	"IconDesc_GolfCart.256.png",
	"IconDesc_HazmatSuit.64.png",
	"IconDesc_HazmatSuit.256.png",
	"IconDesc_SnowballMittens.64.png",
	"IconDesc_SnowballMittens.256.png",
	"IconDesc_Hoverpack.64.png",
	"IconDesc_Hoverpack.256.png",
	"Jetpack.64.png",
	"Jetpack.256.png",
	"IconDesc_SprintingStilts.64.png",
	"IconDesc_SprintingStilts.256.png",
	"RebarGun.64.png",
	"RebarGun.256.png",
	"Detonator.64.png",
	"Detonator.256.png",
	"PortableMiner.64.png",
	"PortableMiner.256.png",
	"RifleMK1.64.png",
	"RifleMK1.256.png",
	"XenoZapper.64.png",
	"XenoZapper.256.png",
	"IconDesc_CaneEquipment.64.png",
	"IconDesc_CaneEquipment.256.png",
	"ShockBaton.64.png",
	"ShockBaton.256.png",
	"IconDesc_Zipline.64.png",
	"IconDesc_Zipline.256.png",
	"IconDesc_Battery.64.png",
	"IconDesc_Battery.256.png",
	"IconDesc_CanePart.64.png",
	"IconDesc_CanePart.256.png",
	"IconDesc_Gift.64.png",
	"IconDesc_Gift.256.png",
	"IconDesc_Snow.64.png",
	"IconDesc_Snow.256.png",
	"IconDesc_XmasBall_Red.64.png",
	"IconDesc_XmasBall_Red.256.png",
	"IconDesc_XmasBall_Blue.64.png",
	"IconDesc_XmasBall_Blue.256.png",
	"IconDesc_XmasBall_Yellow.64.png",
	"IconDesc_XmasBall_Yellow.256.png",
	"IconDesc_XmasBall_Silver.64.png",
	"IconDesc_XmasBall_Silver.256.png",
	"IconDesc_Balls.64.png",
	"IconDesc_Balls.256.png",
	"IconDesc_Bow.64.png",
	"IconDesc_Bow.256.png",
	"IconDesc_Branch.64.png",
	"IconDesc_Branch.256.png",
	"IconDesc_XmasLights.64.png",
	"IconDesc_XmasLights.256.png",
	"IconDesc_Star.64.png",
	"IconDesc_Star.256.png",
	"IconDesc_Wreath.64.png",
	"IconDesc_Wreath.256.png",
	"Wat_1.64.png",
	"Wat_1.256.png",
	"Wat_2.64.png",
	"Wat_2.256.png",
	"HardDrive.64.png",
	"HardDrive.256.png",
	"PowerSlugGreen.64.png",
	"PowerSlugGreen.256.png",
	"PowerSlugYellow.64.png",
	"PowerSlugYellow.256.png",
	"PowerSlugPurple.64.png",
	"PowerSlugPurple.256.png",
	"PowerShard.64.png",
	"PowerShard.256.png",
	"LiquidAlumina_Pipe.64.png",
	"LiquidAlumina_Pipe.256.png",
	"IconDesc_AluminiumCasing.64.png",
	"IconDesc_AluminiumCasing.256.png",
	"IconDesc_AluminiumIngot.64.png",
	"IconDesc_AluminiumIngot.256.png",
	"IconDesc_AluminiumSheet.64.png",
	"IconDesc_AluminiumSheet.256.png",
	"IconDesc_Heatsink.64.png",
	"IconDesc_Heatsink.256.png",
	"IconDesc_AluminiumScrap.64.png",
	"IconDesc_AluminiumScrap.256.png",
	"IconDesc_Cables.64.png",
	"IconDesc_Cables.256.png",
	"Rifle_Magazine.64.png",
	"Rifle_Magazine.256.png",
	"IconDesc_Concrete.64.png",
	"IconDesc_Concrete.256.png",
	"IconDesc_CircuitBoard.64.png",
	"IconDesc_CircuitBoard.256.png",
	"IconDesc_AILimiter.64.png",
	"IconDesc_AILimiter.256.png",
	"IconDesc_ColorCartridge.64.png",
	"IconDesc_ColorCartridge.256.png",
	"CompactedCoal.64.png",
	"CompactedCoal.256.png",
	"IconDesc_Computer.64.png",
	"IconDesc_Computer.256.png",
	"IconDesc_QuantumComputer.64.png",
	"IconDesc_QuantumComputer.256.png",
	"IconDesc_SuperComputer.64.png",
	"IconDesc_SuperComputer.256.png",
	"IconDesc_CoolingSystem.64.png",
	"IconDesc_CoolingSystem.256.png",
	"IconDesc_CopperDust.64.png",
	"IconDesc_CopperDust.256.png",
	"IconDesc_CopperIngot.64.png",
	"IconDesc_CopperIngot.256.png",
	"IconDesc_CopperSheet.64.png",
	"IconDesc_CopperSheet.256.png",
	"IconDesc_CrystalOscillator.64.png",
	"IconDesc_CrystalOscillator.256.png",
	"IconDesc_DarkMatter.64.png",
	"IconDesc_DarkMatter.256.png",
	"IconDesc_ElectromagneticControlRod.64.png",
	"IconDesc_ElectromagneticControlRod.256.png",
	"IconDesc_GasMaskFilter.64.png",
	"IconDesc_GasMaskFilter.256.png",
	"IconDesc_EmptyCannister.64.png",
	"IconDesc_EmptyCannister.256.png",
	"IconDesc_Fuel.64.png",
	"IconDesc_Fuel.256.png",
	"IconDesc_LiquidFuel_Pipe.64.png",
	"IconDesc_LiquidFuel_Pipe.256.png",
	"IconDesc_PressureTank.64.png",
	"IconDesc_PressureTank.256.png",
	"IconDesc_CateriumIngot.64.png",
	"IconDesc_CateriumIngot.256.png",
	"IconDesc_Gunpowder.64.png",
	"IconDesc_Gunpowder.256.png",
	"IconDesc_LiquidHeavyOilResidue_Pipe.64.png",
	"IconDesc_LiquidHeavyOilResidue_Pipe.256.png",
	"OilResidue.64.png",
	"OilResidue.256.png",
	"IconDesc_HighSpeedConnector.64.png",
	"IconDesc_HighSpeedConnector.256.png",
	"IconDesc_Quickwire.64.png",
	"IconDesc_Quickwire.256.png",
	"IconDesc_HubParts.64.png",
	"IconDesc_HubParts.256.png",
	"IconDesc_LiquidSulfuricAcid_Pipe.64.png",
	"IconDesc_LiquidSulfuricAcid_Pipe.256.png",
	"IconDesc_HazmatFilter.64.png",
	"IconDesc_HazmatFilter.256.png",
	"IconDesc_IronIngot.64.png",
	"IconDesc_IronIngot.256.png",
	"IconDesc_IronPlates.64.png",
	"IconDesc_IronPlates.256.png",
	"IconDesc_ReinforcedIronPlates.64.png",
	"IconDesc_ReinforcedIronPlates.256.png",
	"IconDesc_IronRods.64.png",
	"IconDesc_IronRods.256.png",
	"IconDesc_IronScrews.64.png",
	"IconDesc_IronScrews.256.png",
	"IconDesc_ModularFrame.64.png",
	"IconDesc_ModularFrame.256.png",
	"IconDesc_FusedModularFrame.64.png",
	"IconDesc_FusedModularFrame.256.png",
	"IconDesc_ModularFrameHeavy.64.png",
	"IconDesc_ModularFrameHeavy.256.png",
	"IconDesc_RadioControlUnit.64.png",
	"IconDesc_RadioControlUnit.256.png",
	"IconDesc_Engine.64.png",
	"IconDesc_Engine.256.png",
	"IconDesc_TurboMotor.64.png",
	"IconDesc_TurboMotor.256.png",
	"IconDesc_NitricAcid.64.png",
	"IconDesc_NitricAcid.256.png",
	"IconDesc_Explosive.64.png",
	"IconDesc_Explosive.256.png",
	"IconDesc_NonFissileUranium.64.png",
	"IconDesc_NonFissileUranium.256.png",
	"IconDesc_NuclearWaste.64.png",
	"IconDesc_NuclearWaste.256.png",
	"IconDesc_PlutoniumWaste.64.png",
	"IconDesc_PlutoniumWaste.256.png",
	"IconDesc_PetroleumCoke.64.png",
	"IconDesc_PetroleumCoke.256.png",
	"IconDesc_Plastic.64.png",
	"IconDesc_Plastic.256.png",
	"IconDesc_EncasedPlutoniumCell.64.png",
	"IconDesc_EncasedPlutoniumCell.256.png",
	"IconDesc_PlutoniumPellet.64.png",
	"IconDesc_PlutoniumPellet.256.png",
	"IconDesc_PolymerResin.64.png",
	"IconDesc_PolymerResin.256.png",
	"IconDesc_ConversionCube.64.png",
	"IconDesc_ConversionCube.256.png",
	"IconDesc_QuantumCrystal.64.png",
	"IconDesc_QuantumCrystal.256.png",
	"IconDesc_SuperPositionOscillator.64.png",
	"IconDesc_SuperPositionOscillator.256.png",
	"IconDesc_QuartzResource.64.png",
	"IconDesc_QuartzResource.256.png",
	"IconDesc_Ficsit_Coupon.64.png",
	"IconDesc_Ficsit_Coupon.256.png",
	"IconDesc_Rotor.64.png",
	"IconDesc_Rotor.256.png",
	"IconDesc_Rubber.64.png",
	"IconDesc_Rubber.256.png",
	"IconDesc_Silica.64.png",
	"IconDesc_Silica.256.png",
	"IconDesc_SnowballProjectile.64.png",
	"IconDesc_SnowballProjectile.256.png",
	"Lock_Icon.64.png",
	"Lock_Icon.256.png",
	"IconDesc_SpelevatorPart_1.64.png",
	"IconDesc_SpelevatorPart_1.256.png",
	"IconDesc_SpelevatorPart_2.64.png",
	"IconDesc_SpelevatorPart_2.256.png",
	"SpelevatorPart_3.64.png",
	"SpelevatorPart_3.256.png",
	"IconDesc_SpelevatorPart_4.64.png",
	"IconDesc_SpelevatorPart_4.256.png",
	"IconDesc_SpelevatorPart_5.64.png",
	"IconDesc_SpelevatorPart_5.256.png",
	"IconDesc_MagneticFieldGenerator.64.png",
	"IconDesc_MagneticFieldGenerator.256.png",
	"IconDesc_AssemblyDirectorSystem.64.png",
	"IconDesc_AssemblyDirectorSystem.256.png",
	"IconDesc_ThermalPropulsionRocket.64.png",
	"IconDesc_ThermalPropulsionRocket.256.png",
	"IconDesc_NuclearPasta.64.png",
	"IconDesc_NuclearPasta.256.png",
	"IconDesc_SpikedRebar.64.png",
	"IconDesc_SpikedRebar.256.png",
	"IconDesc_Stator.64.png",
	"IconDesc_Stator.256.png",
	"IconDesc_SteelIngot.64.png",
	"IconDesc_SteelIngot.256.png",
	"IconDesc_SteelPipe.64.png",
	"IconDesc_SteelPipe.256.png",
	"IconDesc_SteelBeam.64.png",
	"IconDesc_SteelBeam.256.png",
	"IconDesc_EncasedSteelBeam.64.png",
	"IconDesc_EncasedSteelBeam.256.png",
	"IconDesc_LiquidTurboFuel_Pipe.64.png",
	"IconDesc_LiquidTurboFuel_Pipe.256.png",
	"IconDesc_TurboFuel.64.png",
	"IconDesc_TurboFuel.256.png",
	"IconDesc_NuclearCell.64.png",
	"IconDesc_NuclearCell.256.png",
	"IconDesc_UraniumPellets.64.png",
	"IconDesc_UraniumPellets.256.png",
	"IconDesc_Wire.64.png",
	"IconDesc_Wire.256.png",
	"Oil.64.png",
	"Oil.256.png",
	"IconDesc_PackagedWater.64.png",
	"IconDesc_PackagedWater.256.png",
	"IconDesc_PackagedAluminaSolution.64.png",
	"IconDesc_PackagedAluminaSolution.256.png",
	"IconDesc_HogPart.64.png",
	"IconDesc_HogPart.256.png",
	"IconDesc_SpitterPart.64.png",
	"IconDesc_SpitterPart.256.png",
	"IconDesc_SolidBiofuel.64.png",
	"IconDesc_SolidBiofuel.256.png",
	"IconDesc_LiquidBiofuel_Pipe.64.png",
	"IconDesc_LiquidBiofuel_Pipe.256.png",
	"IconDesc_LiquidBiofuel.64.png",
	"IconDesc_LiquidBiofuel.256.png",
	"IconDesc_Fabric.64.png",
	"IconDesc_Fabric.256.png",
	"FlowerPetals_Final.64.png",
	"FlowerPetals_Final.256.png",
	"IconDesc_Biomass_Final.64.png",
	"IconDesc_Biomass_Final.256.png",
	"IconDesc_Leaves.64.png",
	"IconDesc_Leaves.256.png",
	"IconDesc_Mycelia.64.png",
	"IconDesc_Mycelia.256.png",
	"IconDesc_Vines.64.png",
	"IconDesc_Vines.256.png",
	"IconDesc_Wood.64.png",
	"IconDesc_Wood.256.png",
	"IconDesc_PackagedNitricAcid.64.png",
	"IconDesc_PackagedNitricAcid.256.png",
	"IconDesc_PackagedNitrogen.64.png",
	"IconDesc_PackagedNitrogen.256.png",
	"IconDesc_PckagedSulphuricAcid.64.png",
	"IconDesc_PckagedSulphuricAcid.256.png",
	"IconDesc_NuclearFuelRod.64.png",
	"IconDesc_NuclearFuelRod.256.png",
	"IconDesc_PlutoniumFuelRod.64.png",
	"IconDesc_PlutoniumFuelRod.256.png",
	"SortRule_None.64.png",
	"SortRule_None.256.png",
	"TXUI_SortRule_Overflow.64.png",
	"TXUI_SortRule_Overflow.256.png",
	"IconDesc_ConveyorPole.64.png",
	"IconDesc_ConveyorPole.256.png",
	"ConveyorPoleMulti.64.png",
	"ConveyorPoleMulti.256.png",
	"HyperTubePole.64.png",
	"HyperTubePole.256.png",
	"IconDesc_HyperTubeStackable.64.png",
	"IconDesc_HyperTubeStackable.256.png",
	"PipePole.64.png",
	"PipePole.256.png",
	"PipePole_Stackable.64.png",
	"PipePole_Stackable.256.png",
	"CoalOre.64.png",
	"CoalOre.256.png",
	"LiquidOil_Pipe.64.png",
	"LiquidOil_Pipe.256.png",
	"Bauxite.64.png",
	"Bauxite.256.png",
	"IconDesc_copper_new.64.png",
	"IconDesc_copper_new.256.png",
	"CateriumOre.64.png",
	"CateriumOre.256.png",
	"IconDesc_iron_new.64.png",
	"IconDesc_iron_new.256.png",
	"UraniumOre.64.png",
	"UraniumOre.256.png",
	"IconDesc_QuartzCrystal.64.png",
	"IconDesc_QuartzCrystal.256.png",
	"SAMOre.64.png",
	"SAMOre.256.png",
	"Stone.64.png",
	"Stone.256.png",
	"Sulfur.64.png",
	"Sulfur.256.png",
	"LiquidWater_Pipe.64.png",
	"LiquidWater_Pipe.256.png",
	"IconDesc_Geyser_128.64.png",
	"IconDesc_Geyser_128.256.png",
	"IconDesc_Geyser.64.png",
	"IconDesc_Geyser.256.png",
	"IconDesc_Drone.64.png",
	"IconDesc_Drone.256.png",
	"Cyberwagon.64.png",
	"Cyberwagon.256.png",
	"Explorer.64.png",
	"Explorer.256.png",
	"Tractor.64.png",
	"Tractor.256.png",
	"Locomotive.64.png",
	"Locomotive.256.png",
	"FreightCar.64.png",
	"FreightCar.256.png",
	"Truck.64.png",
	"Truck.256.png",
	"Reset_Icon.64.png",
	"Reset_Icon.256.png"]

const entries = {
	TXUI_SortRule_AnyUndefined_64,
	TXUI_SortRule_AnyUndefined_256,
	Fences_64,
	Fences_256,
	Foundations_8x1_64,
	Foundations_8x1_256,
	Foundations_8x2_64,
	Foundations_8x2_256,
	Foundations_8x4_64,
	Foundations_8x4_256,
	FrameworkFoundation_64,
	FrameworkFoundation_256,
	Foundation_Glass_64,
	Foundation_Glass_256,
	Pillar_Bottom_64,
	Pillar_Bottom_256,
	Pillar_Middle_64,
	Pillar_Middle_256,
	Pillar_Top_64,
	Pillar_Top_256,
	QuarterPipe_01_64,
	QuarterPipe_01_256,
	QuarterPipe_02_64,
	QuarterPipe_02_256,
	QuarterPipe_Corner_01_64,
	QuarterPipe_Corner_01_256,
	QuarterPipe_Corner_02_64,
	QuarterPipe_Corner_02_256,
	IconDesc_Cnr_QuarterPipe_03_64,
	IconDesc_Cnr_QuarterPipe_03_256,
	IconDesc_Cnr_QuarterPipe_04_64,
	IconDesc_Cnr_QuarterPipe_04_256,
	IconDesc_Ladder_64,
	IconDesc_Ladder_256,
	Ramp8x1_64,
	Ramp8x1_256,
	Ramps_small_64,
	Ramps_small_256,
	Ramps_64,
	Ramps_256,
	Ramp_8x4_Inverted_64,
	Ramp_8x4_Inverted_256,
	Ramp_8x8x4_64,
	Ramp_8x8x4_256,
	TXUI_Foundation_Corner_8x1_64,
	TXUI_Foundation_Corner_8x1_256,
	TXUI_Ramp_Corner_8x1_64,
	TXUI_Ramp_Corner_8x1_256,
	TXUI_Foundation_Corner_8x2_64,
	TXUI_Foundation_Corner_8x2_256,
	TXUI_Ramp_Corner_8x2_64,
	TXUI_Ramp_Corner_8x2_256,
	TXUI_Foundation_Corner_8x4_64,
	TXUI_Foundation_Corner_8x4_256,
	TXUI_Ramp_Corner_8x4_64,
	TXUI_Ramp_Corner_8x4_256,
	IconDesc_Dbl_Ramp_8x8x2_01_64,
	IconDesc_Dbl_Ramp_8x8x2_01_256,
	IconDesc_Dbl_Ramp_8x8x1_01_64,
	IconDesc_Dbl_Ramp_8x8x1_01_256,
	IconDesc_RampInverted_8x8x1_64,
	IconDesc_RampInverted_8x8x1_256,
	IconDesc_C_Inv_Ramp_8x1_01_64,
	IconDesc_C_Inv_Ramp_8x1_01_256,
	IconDesc_C_Inv_Ramp_8x1_02_64,
	IconDesc_C_Inv_Ramp_8x1_02_256,
	IconDesc_RampInverted_8x8x2_64,
	IconDesc_RampInverted_8x8x2_256,
	IconDesc_C_Inv_Ramp_8x2_01_64,
	IconDesc_C_Inv_Ramp_8x2_01_256,
	IconDesc_C_Inv_Ramp_8x2_02_64,
	IconDesc_C_Inv_Ramp_8x2_02_256,
	IconDesc_C_Inv_Ramp_8x4_01_64,
	IconDesc_C_Inv_Ramp_8x4_01_256,
	IconDesc_C_Inv_Ramp_8x3_02_64,
	IconDesc_C_Inv_Ramp_8x3_02_256,
	T_Stair_1b_64,
	T_Stair_1b_256,
	StairLeft_64,
	StairLeft_256,
	StairRight_64,
	StairRight_256,
	WalkwayCross_64,
	WalkwayCross_256,
	WalkwayRamp_64,
	WalkwayRamp_256,
	WalkwayStraight_64,
	WalkwayStraight_256,
	WalkwayTCross_64,
	WalkwayTCross_256,
	WalkwayTurn_64,
	WalkwayTurn_256,
	Wall_Orange_64,
	Wall_Orange_256,
	Wall_Grey_64,
	Wall_Grey_256,
	Wall_Conveyor_x3_Orange_64,
	Wall_Conveyor_x3_Orange_256,
	Wall_Conveyor_x3_Grey_64,
	Wall_Conveyor_x3_Grey_256,
	Wall_Conveyor_x2_Orange_64,
	Wall_Conveyor_x2_Orange_256,
	Wall_Conveyor_x2_Grey_64,
	Wall_Conveyor_x2_Grey_256,
	Wall_Conveyor_x1_Orange_64,
	Wall_Conveyor_x1_Orange_256,
	Wall_Conveyor_x1_Grey_64,
	Wall_Conveyor_x1_Grey_256,
	Wall_Conveyor_Perpendicular_Orange_64,
	Wall_Conveyor_Perpendicular_Orange_256,
	Wall_Conveyor_Perpendicular_Grey_64,
	Wall_Conveyor_Perpendicular_Grey_256,
	Wall_Door_Center_Orange_64,
	Wall_Door_Center_Orange_256,
	Wall_Door_Center_Grey_64,
	Wall_Door_Center_Grey_256,
	Wall_Door_Left_Orange_64,
	Wall_Door_Left_Orange_256,
	Wall_Door_Left_Grey_64,
	Wall_Door_Left_Grey_256,
	Wall_Door_Right_Orange_64,
	Wall_Door_Right_Orange_256,
	Wall_Door_Right_Grey_64,
	Wall_Door_Right_Grey_256,
	Wall_Orange_Gate_64,
	Wall_Orange_Gate_256,
	Wall_Window_8x4_01_64,
	Wall_Window_8x4_01_256,
	Wall_Window_8x4_02_64,
	Wall_Window_8x4_02_256,
	Wall_Window_8x4_03_64,
	Wall_Window_8x4_03_256,
	Wall_Window_8x4_04_64,
	Wall_Window_8x4_04_256,
	AssemblerMk1_64,
	AssemblerMk1_256,
	IconDesc_Blender_64,
	IconDesc_Blender_256,
	ConveyorMerger_64,
	ConveyorMerger_256,
	ConveyorSplitter_64,
	ConveyorSplitter_256,
	ProgrammableSplitter_64,
	ProgrammableSplitter_256,
	SmartSplitter_64,
	SmartSplitter_256,
	IconDesc_CeilingLight_64,
	IconDesc_CeilingLight_256,
	ConstructorMk1_64,
	ConstructorMk1_256,
	ConveyorMk1_64,
	ConveyorMk1_256,
	ConveyorMk2_64,
	ConveyorMk2_256,
	ConveyorMk3_64,
	ConveyorMk3_256,
	ConveyorMk4_64,
	ConveyorMk4_256,
	ConveyorMk5_64,
	ConveyorMk5_256,
	ConveyorLiftMK1_64,
	ConveyorLiftMK1_256,
	ConveyorLiftMK2_64,
	ConveyorLiftMK2_256,
	ConveyorLiftMK3_64,
	ConveyorLiftMK3_256,
	ConveyorLiftMK4_64,
	ConveyorLiftMK4_256,
	ConveyorLiftMK5_64,
	ConveyorLiftMK5_256,
	ConveyorPole_Wall_64,
	ConveyorPole_Wall_256,
	IconDesc_DronePort_64,
	IconDesc_DronePort_256,
	IconDesc_FloodLight_64,
	IconDesc_FloodLight_256,
	IconDesc_FloodlightWall_64,
	IconDesc_FloodlightWall_256,
	Foundry_64,
	Foundry_256,
	IconDesc_Extractor_64,
	IconDesc_Extractor_256,
	IconDesc_Smasher_64,
	IconDesc_Smasher_256,
	BiomassGenerator_64,
	BiomassGenerator_256,
	CoalGenerator_64,
	CoalGenerator_256,
	FuelGenerator_64,
	FuelGenerator_256,
	GeoThermalPowerGenerator_64,
	GeoThermalPowerGenerator_256,
	NuclearPowerplant_64,
	NuclearPowerplant_256,
	IconDesc_HadronCollider_64,
	IconDesc_HadronCollider_256,
	IconDesc_ChristmasTree_64,
	IconDesc_ChristmasTree_256,
	IconDesc_HyperTube_WallHole_64,
	IconDesc_HyperTube_WallHole_256,
	IconDesc_HyperTube_WallSupport_64,
	IconDesc_HyperTube_WallSupport_256,
	FluidStorageIndustrial_64,
	FluidStorageIndustrial_256,
	IconDesc_JumpPad_64,
	IconDesc_JumpPad_256,
	LandingPad_64,
	LandingPad_256,
	IconDesc_LightsControlPanel_64,
	IconDesc_LightsControlPanel_256,
	LookOutTower_64,
	LookOutTower_256,
	MAM_64,
	MAM_256,
	Manufacturer_64,
	Manufacturer_256,
	MinerMk1_64,
	MinerMk1_256,
	MinerMk2_64,
	MinerMk2_256,
	MinerMk3_64,
	MinerMk3_256,
	OilPump_64,
	OilPump_256,
	IconDesc_OilRefinery_64,
	IconDesc_OilRefinery_256,
	IconDesc_Packager_64,
	IconDesc_Packager_256,
	HyperTube_64,
	HyperTube_256,
	HyperTubeStart_64,
	HyperTubeStart_256,
	PipelineJunction_64,
	PipelineJunction_256,
	IconDesc_Pipes_64,
	IconDesc_Pipes_256,
	IconDesc_PipeMK2_64,
	IconDesc_PipeMK2_256,
	PipeSupportWall_64,
	PipeSupportWall_256,
	PipeSupportWallHole_64,
	PipeSupportWallHole_256,
	PipePump_64,
	PipePump_256,
	IconDesc_PipePumpMK2_64,
	IconDesc_PipePumpMK2_256,
	IconDesc_Valve_64,
	IconDesc_Valve_256,
	Powerline_64,
	Powerline_256,
	PowerPoleMk1_64,
	PowerPoleMk1_256,
	PowerPoleMk2_64,
	PowerPoleMk2_256,
	PowerPoleMk3_64,
	PowerPoleMk3_256,
	PowerPoleWall_MK1_64,
	PowerPoleWall_MK1_256,
	PowerPoleWall_MK2_64,
	PowerPoleWall_MK2_256,
	PowerPoleWall_MK3_64,
	PowerPoleWall_MK3_256,
	PowerPoleWallDouble_MK1_64,
	PowerPoleWallDouble_MK1_256,
	PowerPoleWallDouble_MK2_64,
	PowerPoleWallDouble_MK2_256,
	PowerPoleWallDouble_MK3_64,
	PowerPoleWallDouble_MK3_256,
	IconDesc_PowerStorage_64,
	IconDesc_PowerStorage_256,
	IconDesc_PowerSwitch_64,
	IconDesc_PowerSwitch_256,
	RadarTower_64,
	RadarTower_256,
	ResourceSink_64,
	ResourceSink_256,
	ResourceSinkShop_64,
	ResourceSinkShop_256,
	SmelterMk1_64,
	SmelterMk1_256,
	SpaceElevator_64,
	SpaceElevator_256,
	StorageContainer_64,
	StorageContainer_256,
	StorageContainerMk2_64,
	StorageContainerMk2_256,
	PlayerStorage_64,
	PlayerStorage_256,
	FluidStorage_64,
	FluidStorage_256,
	IconDesc_StreetLight_64,
	IconDesc_StreetLight_256,
	Hub_64,
	Hub_256,
	DockingStation_64,
	DockingStation_256,
	TrainDockingFluid_64,
	TrainDockingFluid_256,
	EmptyPlatform_64,
	EmptyPlatform_256,
	TrainStation_64,
	TrainStation_256,
	Image_0_White_64,
	Image_0_White_256,
	Track_64,
	Track_256,
	TruckStation_64,
	TruckStation_256,
	Waterpump_64,
	Waterpump_256,
	Workbench_64,
	Workbench_256,
	Workshop_64,
	Workshop_256,
	IconDesc_CandyCaneDecor_64,
	IconDesc_CandyCaneDecor_256,
	IconDesc_SnowDispenser_64,
	IconDesc_SnowDispenser_256,
	IconDesc_Snowman_64,
	IconDesc_Snowman_256,
	IconDesc_ChristmasTreeBare_64,
	IconDesc_ChristmasTreeBare_256,
	IconDesc_WreathNoBow_64,
	IconDesc_WreathNoBow_256,
	Berry_64,
	Berry_256,
	Mushroom_64,
	Mushroom_256,
	Nut_new_64,
	Nut_new_256,
	IconDesc_Beacon_64,
	IconDesc_Beacon_256,
	Parachute_64,
	Parachute_256,
	Inhaler_64,
	Inhaler_256,
	Award_Statue_Character_Silver_64,
	Award_Statue_Character_Silver_256,
	Award_Statue_Character_Bronze_64,
	Award_Statue_Character_Bronze_256,
	Award_Statue_Character_Gold_64,
	Award_Statue_Character_Gold_256,
	Award_Statue_LizardDoggo_64,
	Award_Statue_LizardDoggo_256,
	Award_Statue_Nut_64,
	Award_Statue_Nut_256,
	Award_Statue_Hog_64,
	Award_Statue_Hog_256,
	Award_Statue_SpaceGiraffe_64,
	Award_Statue_SpaceGiraffe_256,
	ColorGun_64,
	ColorGun_256,
	Chainsaw_64,
	Chainsaw_256,
	IconDesc_CoffeeCup_64,
	IconDesc_CoffeeCup_256,
	GasMask_64,
	GasMask_256,
	ObjectScanner_64,
	ObjectScanner_256,
	IconDesc_CupGold_64,
	IconDesc_CupGold_256,
	IconDesc_GolfCart_64,
	IconDesc_GolfCart_256,
	IconDesc_HazmatSuit_64,
	IconDesc_HazmatSuit_256,
	IconDesc_SnowballMittens_64,
	IconDesc_SnowballMittens_256,
	IconDesc_Hoverpack_64,
	IconDesc_Hoverpack_256,
	Jetpack_64,
	Jetpack_256,
	IconDesc_SprintingStilts_64,
	IconDesc_SprintingStilts_256,
	RebarGun_64,
	RebarGun_256,
	Detonator_64,
	Detonator_256,
	PortableMiner_64,
	PortableMiner_256,
	RifleMK1_64,
	RifleMK1_256,
	XenoZapper_64,
	XenoZapper_256,
	IconDesc_CaneEquipment_64,
	IconDesc_CaneEquipment_256,
	ShockBaton_64,
	ShockBaton_256,
	IconDesc_Zipline_64,
	IconDesc_Zipline_256,
	IconDesc_Battery_64,
	IconDesc_Battery_256,
	IconDesc_CanePart_64,
	IconDesc_CanePart_256,
	IconDesc_Gift_64,
	IconDesc_Gift_256,
	IconDesc_Snow_64,
	IconDesc_Snow_256,
	IconDesc_XmasBall_Red_64,
	IconDesc_XmasBall_Red_256,
	IconDesc_XmasBall_Blue_64,
	IconDesc_XmasBall_Blue_256,
	IconDesc_XmasBall_Yellow_64,
	IconDesc_XmasBall_Yellow_256,
	IconDesc_XmasBall_Silver_64,
	IconDesc_XmasBall_Silver_256,
	IconDesc_Balls_64,
	IconDesc_Balls_256,
	IconDesc_Bow_64,
	IconDesc_Bow_256,
	IconDesc_Branch_64,
	IconDesc_Branch_256,
	IconDesc_XmasLights_64,
	IconDesc_XmasLights_256,
	IconDesc_Star_64,
	IconDesc_Star_256,
	IconDesc_Wreath_64,
	IconDesc_Wreath_256,
	Wat_1_64,
	Wat_1_256,
	Wat_2_64,
	Wat_2_256,
	HardDrive_64,
	HardDrive_256,
	PowerSlugGreen_64,
	PowerSlugGreen_256,
	PowerSlugYellow_64,
	PowerSlugYellow_256,
	PowerSlugPurple_64,
	PowerSlugPurple_256,
	PowerShard_64,
	PowerShard_256,
	LiquidAlumina_Pipe_64,
	LiquidAlumina_Pipe_256,
	IconDesc_AluminiumCasing_64,
	IconDesc_AluminiumCasing_256,
	IconDesc_AluminiumIngot_64,
	IconDesc_AluminiumIngot_256,
	IconDesc_AluminiumSheet_64,
	IconDesc_AluminiumSheet_256,
	IconDesc_Heatsink_64,
	IconDesc_Heatsink_256,
	IconDesc_AluminiumScrap_64,
	IconDesc_AluminiumScrap_256,
	IconDesc_Cables_64,
	IconDesc_Cables_256,
	Rifle_Magazine_64,
	Rifle_Magazine_256,
	IconDesc_Concrete_64,
	IconDesc_Concrete_256,
	IconDesc_CircuitBoard_64,
	IconDesc_CircuitBoard_256,
	IconDesc_AILimiter_64,
	IconDesc_AILimiter_256,
	IconDesc_ColorCartridge_64,
	IconDesc_ColorCartridge_256,
	CompactedCoal_64,
	CompactedCoal_256,
	IconDesc_Computer_64,
	IconDesc_Computer_256,
	IconDesc_QuantumComputer_64,
	IconDesc_QuantumComputer_256,
	IconDesc_SuperComputer_64,
	IconDesc_SuperComputer_256,
	IconDesc_CoolingSystem_64,
	IconDesc_CoolingSystem_256,
	IconDesc_CopperDust_64,
	IconDesc_CopperDust_256,
	IconDesc_CopperIngot_64,
	IconDesc_CopperIngot_256,
	IconDesc_CopperSheet_64,
	IconDesc_CopperSheet_256,
	IconDesc_CrystalOscillator_64,
	IconDesc_CrystalOscillator_256,
	IconDesc_DarkMatter_64,
	IconDesc_DarkMatter_256,
	IconDesc_ElectromagneticControlRod_64,
	IconDesc_ElectromagneticControlRod_256,
	IconDesc_GasMaskFilter_64,
	IconDesc_GasMaskFilter_256,
	IconDesc_EmptyCannister_64,
	IconDesc_EmptyCannister_256,
	IconDesc_Fuel_64,
	IconDesc_Fuel_256,
	IconDesc_LiquidFuel_Pipe_64,
	IconDesc_LiquidFuel_Pipe_256,
	IconDesc_PressureTank_64,
	IconDesc_PressureTank_256,
	IconDesc_CateriumIngot_64,
	IconDesc_CateriumIngot_256,
	IconDesc_Gunpowder_64,
	IconDesc_Gunpowder_256,
	IconDesc_LiquidHeavyOilResidue_Pipe_64,
	IconDesc_LiquidHeavyOilResidue_Pipe_256,
	OilResidue_64,
	OilResidue_256,
	IconDesc_HighSpeedConnector_64,
	IconDesc_HighSpeedConnector_256,
	IconDesc_Quickwire_64,
	IconDesc_Quickwire_256,
	IconDesc_HubParts_64,
	IconDesc_HubParts_256,
	IconDesc_LiquidSulfuricAcid_Pipe_64,
	IconDesc_LiquidSulfuricAcid_Pipe_256,
	IconDesc_HazmatFilter_64,
	IconDesc_HazmatFilter_256,
	IconDesc_IronIngot_64,
	IconDesc_IronIngot_256,
	IconDesc_IronPlates_64,
	IconDesc_IronPlates_256,
	IconDesc_ReinforcedIronPlates_64,
	IconDesc_ReinforcedIronPlates_256,
	IconDesc_IronRods_64,
	IconDesc_IronRods_256,
	IconDesc_IronScrews_64,
	IconDesc_IronScrews_256,
	IconDesc_ModularFrame_64,
	IconDesc_ModularFrame_256,
	IconDesc_FusedModularFrame_64,
	IconDesc_FusedModularFrame_256,
	IconDesc_ModularFrameHeavy_64,
	IconDesc_ModularFrameHeavy_256,
	IconDesc_RadioControlUnit_64,
	IconDesc_RadioControlUnit_256,
	IconDesc_Engine_64,
	IconDesc_Engine_256,
	IconDesc_TurboMotor_64,
	IconDesc_TurboMotor_256,
	IconDesc_NitricAcid_64,
	IconDesc_NitricAcid_256,
	IconDesc_Explosive_64,
	IconDesc_Explosive_256,
	IconDesc_NonFissileUranium_64,
	IconDesc_NonFissileUranium_256,
	IconDesc_NuclearWaste_64,
	IconDesc_NuclearWaste_256,
	IconDesc_PlutoniumWaste_64,
	IconDesc_PlutoniumWaste_256,
	IconDesc_PetroleumCoke_64,
	IconDesc_PetroleumCoke_256,
	IconDesc_Plastic_64,
	IconDesc_Plastic_256,
	IconDesc_EncasedPlutoniumCell_64,
	IconDesc_EncasedPlutoniumCell_256,
	IconDesc_PlutoniumPellet_64,
	IconDesc_PlutoniumPellet_256,
	IconDesc_PolymerResin_64,
	IconDesc_PolymerResin_256,
	IconDesc_ConversionCube_64,
	IconDesc_ConversionCube_256,
	IconDesc_QuantumCrystal_64,
	IconDesc_QuantumCrystal_256,
	IconDesc_SuperPositionOscillator_64,
	IconDesc_SuperPositionOscillator_256,
	IconDesc_QuartzResource_64,
	IconDesc_QuartzResource_256,
	IconDesc_Ficsit_Coupon_64,
	IconDesc_Ficsit_Coupon_256,
	IconDesc_Rotor_64,
	IconDesc_Rotor_256,
	IconDesc_Rubber_64,
	IconDesc_Rubber_256,
	IconDesc_Silica_64,
	IconDesc_Silica_256,
	IconDesc_SnowballProjectile_64,
	IconDesc_SnowballProjectile_256,
	Lock_Icon_64,
	Lock_Icon_256,
	IconDesc_SpelevatorPart_1_64,
	IconDesc_SpelevatorPart_1_256,
	IconDesc_SpelevatorPart_2_64,
	IconDesc_SpelevatorPart_2_256,
	SpelevatorPart_3_64,
	SpelevatorPart_3_256,
	IconDesc_SpelevatorPart_4_64,
	IconDesc_SpelevatorPart_4_256,
	IconDesc_SpelevatorPart_5_64,
	IconDesc_SpelevatorPart_5_256,
	IconDesc_MagneticFieldGenerator_64,
	IconDesc_MagneticFieldGenerator_256,
	IconDesc_AssemblyDirectorSystem_64,
	IconDesc_AssemblyDirectorSystem_256,
	IconDesc_ThermalPropulsionRocket_64,
	IconDesc_ThermalPropulsionRocket_256,
	IconDesc_NuclearPasta_64,
	IconDesc_NuclearPasta_256,
	IconDesc_SpikedRebar_64,
	IconDesc_SpikedRebar_256,
	IconDesc_Stator_64,
	IconDesc_Stator_256,
	IconDesc_SteelIngot_64,
	IconDesc_SteelIngot_256,
	IconDesc_SteelPipe_64,
	IconDesc_SteelPipe_256,
	IconDesc_SteelBeam_64,
	IconDesc_SteelBeam_256,
	IconDesc_EncasedSteelBeam_64,
	IconDesc_EncasedSteelBeam_256,
	IconDesc_LiquidTurboFuel_Pipe_64,
	IconDesc_LiquidTurboFuel_Pipe_256,
	IconDesc_TurboFuel_64,
	IconDesc_TurboFuel_256,
	IconDesc_NuclearCell_64,
	IconDesc_NuclearCell_256,
	IconDesc_UraniumPellets_64,
	IconDesc_UraniumPellets_256,
	IconDesc_Wire_64,
	IconDesc_Wire_256,
	Oil_64,
	Oil_256,
	IconDesc_PackagedWater_64,
	IconDesc_PackagedWater_256,
	IconDesc_PackagedAluminaSolution_64,
	IconDesc_PackagedAluminaSolution_256,
	IconDesc_HogPart_64,
	IconDesc_HogPart_256,
	IconDesc_SpitterPart_64,
	IconDesc_SpitterPart_256,
	IconDesc_SolidBiofuel_64,
	IconDesc_SolidBiofuel_256,
	IconDesc_LiquidBiofuel_Pipe_64,
	IconDesc_LiquidBiofuel_Pipe_256,
	IconDesc_LiquidBiofuel_64,
	IconDesc_LiquidBiofuel_256,
	IconDesc_Fabric_64,
	IconDesc_Fabric_256,
	FlowerPetals_Final_64,
	FlowerPetals_Final_256,
	IconDesc_Biomass_Final_64,
	IconDesc_Biomass_Final_256,
	IconDesc_Leaves_64,
	IconDesc_Leaves_256,
	IconDesc_Mycelia_64,
	IconDesc_Mycelia_256,
	IconDesc_Vines_64,
	IconDesc_Vines_256,
	IconDesc_Wood_64,
	IconDesc_Wood_256,
	IconDesc_PackagedNitricAcid_64,
	IconDesc_PackagedNitricAcid_256,
	IconDesc_PackagedNitrogen_64,
	IconDesc_PackagedNitrogen_256,
	IconDesc_PckagedSulphuricAcid_64,
	IconDesc_PckagedSulphuricAcid_256,
	IconDesc_NuclearFuelRod_64,
	IconDesc_NuclearFuelRod_256,
	IconDesc_PlutoniumFuelRod_64,
	IconDesc_PlutoniumFuelRod_256,
	SortRule_None_64,
	SortRule_None_256,
	TXUI_SortRule_Overflow_64,
	TXUI_SortRule_Overflow_256,
	IconDesc_ConveyorPole_64,
	IconDesc_ConveyorPole_256,
	ConveyorPoleMulti_64,
	ConveyorPoleMulti_256,
	HyperTubePole_64,
	HyperTubePole_256,
	IconDesc_HyperTubeStackable_64,
	IconDesc_HyperTubeStackable_256,
	PipePole_64,
	PipePole_256,
	PipePole_Stackable_64,
	PipePole_Stackable_256,
	CoalOre_64,
	CoalOre_256,
	LiquidOil_Pipe_64,
	LiquidOil_Pipe_256,
	Bauxite_64,
	Bauxite_256,
	IconDesc_copper_new_64,
	IconDesc_copper_new_256,
	CateriumOre_64,
	CateriumOre_256,
	IconDesc_iron_new_64,
	IconDesc_iron_new_256,
	UraniumOre_64,
	UraniumOre_256,
	IconDesc_QuartzCrystal_64,
	IconDesc_QuartzCrystal_256,
	SAMOre_64,
	SAMOre_256,
	Stone_64,
	Stone_256,
	Sulfur_64,
	Sulfur_256,
	LiquidWater_Pipe_64,
	LiquidWater_Pipe_256,
	IconDesc_Geyser_128_64,
	IconDesc_Geyser_128_256,
	IconDesc_Geyser_64,
	IconDesc_Geyser_256,
	IconDesc_Drone_64,
	IconDesc_Drone_256,
	Cyberwagon_64,
	Cyberwagon_256,
	Explorer_64,
	Explorer_256,
	Tractor_64,
	Tractor_256,
	Locomotive_64,
	Locomotive_256,
	FreightCar_64,
	FreightCar_256,
	Truck_64,
	Truck_256,
	Reset_Icon_64,
	Reset_Icon_256
}

export default entries;
