export const NODE_WIDTH = 145;
export const NODE_HEIGHT = 145;
export const GRID_SIZE = 146 / 2;
export const MACHINE_SIZE = 100;
export const TOP_HEIGHT = 110;
export const BOX_THICKNESS = 4;
export const BOX_RADIUS = 10;
export const BOX_LINE_THICKNESS = 3;
export const CIRCLE_RADIUS = 8;
export const CIRCLE_THICKNESS = 3;
export const ITEM_SIZE = 64;
export const MACHINE_ICON_SIZE = 256;
export const BADGE_THICKNESS = 2;
export const BADGE_RADIUS = 6;
export const BADGE_WIDTH = 55;
export const BADGE_HEIGHT = 30;
export const SMALL_BADGE_WIDTH = 30;
export const NODE_HIGHLIGHT_THICKNESS = 22;
export const LINE_THICKNESS = 4;
export const LINE_HIGHLIGHT_THICKNESS = 13;
