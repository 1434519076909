export enum EFrackingSatelliteState {
  FSS_Untouched,
  FSS_Active,
  FSS_Inactive,
}

export const EFrackingSatelliteStateDisplayName = {
  [EFrackingSatelliteState.FSS_Untouched]: 'Untouched',
  [EFrackingSatelliteState.FSS_Active]: 'Active',
  [EFrackingSatelliteState.FSS_Inactive]: 'Inactive',
};
