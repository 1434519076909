export enum EHoverConnectionStatus {
  HCS_NULL,
  HCS_OK,
  HCS_NO_POWER,
  HCS_OUT_OF_RANGE,
}

export const EHoverConnectionStatusDisplayName = {
  [EHoverConnectionStatus.HCS_NULL]: 'Null',
  [EHoverConnectionStatus.HCS_OK]: 'OK',
  [EHoverConnectionStatus.HCS_NO_POWER]: 'No Power',
  [EHoverConnectionStatus.HCS_OUT_OF_RANGE]: 'Out of Range',
};
