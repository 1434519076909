export enum EHoverPackMode {
  HPM_Inactive,
  HPM_Hover,
  HPM_HoverSlowFall,
}

export const EHoverPackModeDisplayName = {
  [EHoverPackMode.HPM_Inactive]: 'Inactive',
  [EHoverPackMode.HPM_Hover]: 'Hover',
  [EHoverPackMode.HPM_HoverSlowFall]: 'Slow Fall',
};
