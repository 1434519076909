export enum EEvents {
  EV_None,
  EV_Christmas,
  EV_Birthday,
  EV_CSSBirthday,
  EV_FirstOfApril,
}

export const EEventsDisplayName = {
  [EEvents.EV_None]: 'None',
  [EEvents.EV_Christmas]: 'Christmas',
  [EEvents.EV_Birthday]: 'Satisfactory Birthday',
  [EEvents.EV_CSSBirthday]: 'CSS Birthday',
  [EEvents.EV_FirstOfApril]: 'First Of April',
};
