export enum EProximityEffectTypes {
  PET_None,
  PET_Bush,
  PET_Tree,
  PET_Rock,
  PET_Flower,
}

export const EProximityEffectTypesDisplayName = {
  [EProximityEffectTypes.PET_None]: 'None',
  [EProximityEffectTypes.PET_Bush]: 'Bush(s)',
  [EProximityEffectTypes.PET_Tree]: 'Tree(s)',
  [EProximityEffectTypes.PET_Rock]: 'Rock(s)',
  [EProximityEffectTypes.PET_Flower]: 'Flower(s)',
};
