/**
 * Enum that specifies what state an option value is in
 */
export enum EOptionValueState {
  /**
   * The that is applied to the cvar and what you receive if you get a value from game user settings. The exception is if you haven't changed the value you will get the default value. Apply will also contain isntanty updated values.
   */
  Applied,

  /**
   * Have we changed but not saved a value
   */
  Pending,

  /**
   * Values that are directly applied when changed. These values are what we should revert to if we revert options changes
   */
  InstantlyUpdated,

  /**
   * Have we changed a value that only should be applied when we restart the session. If we are in the main menu no values will be associated with this state
   */
  PendingSessionRestart,

  /**
   * SessionRestart, // Have we changed a value that should should be applied when we restart the session. Changes to these in main menu get applied directly
   * Have we changed a value that only should be applied when we restart the game.
   */
  PendingGameRestart,

  /**
   * GameRestart, // Have we changed a value that should should be applied when we restart the game.
   * The default value of this option
   */
  Default,
}
