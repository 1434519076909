export enum EOptionNetmodeType {
  ONT_ServerAndClient,
  ONT_OnlyServer,
  ONT_OnlyClient,
}

export const EOptionNetmodeTypeDisplayName = {
  [EOptionNetmodeType.ONT_ServerAndClient]: 'Server and Client',
  [EOptionNetmodeType.ONT_OnlyServer]: 'Only Server',
  [EOptionNetmodeType.ONT_OnlyClient]: 'Only Client',
};
