/**
 * Request Docking
 */
export enum EDroneDockingRequestState {
  STravelToQueueLocation,
  SFlyToQueueLocation,
  STravelToDockingAirLocation,
  SFlyToDockingAirLocation,
  SDescendToDockingLocation,
}
