export enum EPowerPoleType {
  PPT_POLE,
  PPT_WALL,
  PPT_WALL_DOUBLE,
}

export const EPowerPoleTypeDisplayName = {
  [EPowerPoleType.PPT_POLE]: 'Pole',
  [EPowerPoleType.PPT_WALL]: 'Wall Plug',
  [EPowerPoleType.PPT_WALL_DOUBLE]: 'Wall Plug Double',
};
