export enum ConnectionTypeEnum {
	'AFGBuildableConveyorBelt' = 1,
	'AFGBuildablePipeline' = 2,
}

export enum buildingEnums {
	'building-assembler-mk1' = 1,
	'building-conveyor-pole-wall' = 2,
	'building-hyper-tube-wall-hole' = 3,
	'building-hyper-tube-wall-support' = 4,
	'building-mam' = 5,
	'building-pipeline-support-wall' = 6,
	'building-pipeline-support-wall-hole' = 7,
	'building-work-bench' = 8,
	'building-work-bench-integrated' = 9,
	'building-workshop' = 10,
	'building-xmass-tree' = 11,
	'building-wreath-decor' = 12,
	'building-conveyor-attachment-merger' = 13,
	'building-conveyor-attachment-splitter' = 14,
	'building-automated-work-bench' = 15,
	'building-converter' = 16,
	'building-conveyor-belt-mk1' = 17,
	'building-conveyor-belt-mk2' = 18,
	'building-conveyor-belt-mk3' = 19,
	'building-conveyor-belt-mk4' = 20,
	'building-conveyor-belt-mk5' = 21,
	'building-conveyor-lift-mk1' = 22,
	'building-conveyor-lift-mk2' = 23,
	'building-conveyor-lift-mk3' = 24,
	'building-conveyor-lift-mk4' = 25,
	'building-conveyor-lift-mk5' = 26,
	'building-truck-station' = 27,
	'building-jump-pad' = 28,
	'building-jump-pad-tilted' = 29,
	'building-landing-pad' = 30,
	'building-lookout-tower' = 31,
	'building-candy-cane-decor' = 32,
	'building-snowman' = 33,
	'building-tree-gift-producer' = 34,
	'building-foundation-8x1-01' = 35,
	'building-foundation-8x2-01' = 36,
	'building-foundation-8x4-01' = 37,
	'building-foundation-frame-01' = 38,
	'building-foundation-glass-01' = 39,
	'building-pillar-base' = 40,
	'building-pillar-middle' = 41,
	'building-pillar-top' = 42,
	'building-quarter-pipe' = 43,
	'building-quarter-pipe-02' = 44,
	'building-quarter-pipe-corner-01' = 45,
	'building-quarter-pipe-corner-02' = 46,
	'building-quarter-pipe-corner-03' = 47,
	'building-quarter-pipe-corner-04' = 48,
	'building-ramp-8x1-01' = 49,
	'building-ramp-8x2-01' = 50,
	'building-ramp-8x4-01' = 51,
	'building-ramp-8x4-inverted-01' = 52,
	'building-ramp-8x8x8' = 53,
	'building-ramp-diagonal-8x1-01' = 54,
	'building-ramp-diagonal-8x1-02' = 55,
	'building-ramp-diagonal-8x2-01' = 56,
	'building-ramp-diagonal-8x2-02' = 57,
	'building-ramp-diagonal-8x4-01' = 58,
	'building-ramp-diagonal-8x4-02' = 59,
	'building-ramp-double' = 60,
	'building-ramp-double-8x1' = 61,
	'building-ramp-inverted-8x1' = 62,
	'building-ramp-inverted-8x1-corner-01' = 63,
	'building-ramp-inverted-8x1-corner-02' = 64,
	'building-ramp-inverted-8x2-01' = 65,
	'building-ramp-inverted-8x2-corner-01' = 66,
	'building-ramp-inverted-8x2-corner-02' = 67,
	'building-ramp-inverted-8x4-corner-01' = 68,
	'building-ramp-inverted-8x4-corner-02' = 69,
	'building-generator-biomass' = 70,
	'building-generator-integrated-biomass' = 71,
	'building-generator-coal' = 72,
	'building-generator-fuel' = 73,
	'building-generator-geo-thermal' = 74,
	'building-generator-nuclear' = 75,
	'building-hub-terminal' = 76,
	'building-jump-pad-adjustable' = 77,
	'building-ladder' = 78,
	'building-constructor-mk1' = 79,
	'building-foundry-mk1' = 80,
	'building-manufacturer-mk1' = 81,
	'building-oil-refinery' = 82,
	'building-packager' = 83,
	'building-smelter-mk1' = 84,
	'building-pipe-hyper' = 85,
	'building-pipeline' = 86,
	'building-pipeline-mk2' = 87,
	'building-pipeline-junction-cross' = 88,
	'building-pipeline-pump' = 89,
	'building-pipeline-pump-mk2' = 90,
	'building-valve' = 91,
	'building-pipe-hyper-support' = 92,
	'building-hyper-pole-stackable' = 93,
	'building-pipeline-support' = 94,
	'building-pipe-support-stackable' = 95,
	'building-industrial-tank' = 96,
	'building-pipe-storage-tank' = 97,
	'building-conveyor-pole' = 98,
	'building-power-pole-mk1' = 99,
	'building-power-pole-mk2' = 100,
	'building-power-pole-mk3' = 101,
	'building-power-pole-wall' = 102,
	'building-power-pole-wall-mk2' = 103,
	'building-power-pole-wall-mk3' = 104,
	'building-power-pole-wall-double' = 105,
	'building-power-pole-wall-double-mk2' = 106,
	'building-power-pole-wall-double-mk3' = 107,
	'building-radar-tower' = 108,
	'building-train-station' = 109,
	'building-railroad-switch-control' = 110,
	'building-railroad-track' = 111,
	'building-railroad-track-integrated' = 112,
	'building-miner-mk1' = 113,
	'building-miner-mk2' = 114,
	'building-miner-mk3' = 115,
	'building-oil-pump' = 116,
	'building-water-pump' = 117,
	'building-resource-sink' = 118,
	'building-resource-sink-shop' = 119,
	'building-snow-dispenser' = 120,
	'building-space-elevator' = 121,
	'building-conveyor-attachment-splitter-programmable' = 122,
	'building-conveyor-attachment-splitter-smart' = 123,
	'building-stair-1b' = 124,
	'building-stairs-left-01' = 125,
	'building-stairs-right-01' = 126,
	'building-storage-container-mk1' = 127,
	'building-storage-container-mk2' = 128,
	'building-storage-integrated' = 129,
	'building-storage-player' = 130,
	'building-trading-post' = 131,
	'building-train-docking-station' = 132,
	'building-train-docking-station-liquid' = 133,
	'building-train-platform-empty' = 134,
	'building-walkway-cross' = 135,
	'building-walkway-ramp' = 136,
	'building-walkway-straight' = 137,
	'building-walkway-t' = 138,
	'building-walkway-trun' = 139,
	'building-fence-01' = 140,
	'building-wall-8x4-01' = 141,
	'building-wall-8x4-02' = 142,
	'building-wall-conveyor-8x4-01' = 143,
	'building-wall-conveyor-8x4-01-steel' = 144,
	'building-wall-conveyor-8x4-02' = 145,
	'building-wall-conveyor-8x4-02-steel' = 146,
	'building-wall-conveyor-8x4-03' = 147,
	'building-wall-conveyor-8x4-03-steel' = 148,
	'building-wall-conveyor-8x4-04' = 149,
	'building-wall-conveyor-8x4-04-steel' = 150,
	'building-wall-door-8x4-01' = 151,
	'building-wall-door-8x4-01-steel' = 152,
	'building-wall-door-8x4-02' = 153,
	'building-wall-door-8x4-02-steel' = 154,
	'building-wall-door-8x4-03' = 155,
	'building-wall-door-8x4-03-steel' = 156,
	'building-wall-gate-8x4-01' = 157,
	'building-wall-window-8x4-01' = 158,
	'building-wall-window-8x4-02' = 159,
	'building-wall-window-8x4-03' = 160,
	'building-wall-window-8x4-04' = 161,
	'building-power-line' = 162,
	'building-xmass-lights-line' = 163,
	'building-conveyor-pole-stackable' = 164,
	'building-pipe-hyper-start' = 165,
	'building-power-switch' = 166,
	'building-drone-station' = 167,
	'building-floodlight-pole' = 168,
	'building-floodlight-wall' = 169,
	'building-fracking-smasher' = 170,
	'building-fracking-extractor' = 171,
	'building-lights-control-panel' = 172,
	'building-ceiling-light' = 173,
	'building-street-light' = 174,
	'building-blender' = 175,
	'building-hadron-collider' = 176,
	'building-power-storage-mk1' = 177,
}

export enum recipeEnums {
	'recipe-train-docking-station' = 1,
	'recipe-train-docking-station-liquid' = 2,
	'recipe-train-platform-empty' = 3,
	'recipe-train-station' = 4,
	'recipe-candy-cane-decor' = 5,
	'recipe-xmass-lights' = 6,
	'recipe-snow-dispenser' = 7,
	'recipe-snowman' = 8,
	'recipe-xmass-tree' = 9,
	'recipe-wreath-decor' = 10,
	'recipe-candy-cane' = 11,
	'recipe-snow' = 12,
	'recipe-xmas-ball1' = 13,
	'recipe-xmas-ball2' = 14,
	'recipe-xmas-ball3' = 15,
	'recipe-xmas-ball4' = 16,
	'recipe-xmas-ball-cluster' = 17,
	'recipe-xmas-bow' = 18,
	'recipe-xmas-branch' = 19,
	'recipe-xmas-star' = 20,
	'recipe-xmas-wreath' = 21,
	'recipe-pipe-hyper-start' = 22,
	'recipe-alternate-adhered-iron-plate' = 23,
	'recipe-alternate-bolted-frame' = 24,
	'recipe-alternate-coated-cable' = 25,
	'recipe-alternate-coated-iron-canister' = 26,
	'recipe-alternate-coated-iron-plate' = 27,
	'recipe-alternate-coke-steel-ingot' = 28,
	'recipe-alternate-copper-alloy-ingot' = 29,
	'recipe-alternate-copper-rotor' = 30,
	'recipe-alternate-diluted-packaged-fuel' = 31,
	'recipe-alternate-electro-aluminum-scrap' = 32,
	'recipe-alternate-electrode-circuit-board' = 33,
	'recipe-alternate-flexible-framework' = 34,
	'recipe-alternate-fused-wire' = 35,
	'recipe-alternate-heavy-flexible-frame' = 36,
	'recipe-alternate-heavy-oil-residue' = 37,
	'recipe-alternate-high-speed-wiring' = 38,
	'recipe-alternate-plastic-smart-plating' = 39,
	'recipe-alternate-polyester-fabric' = 40,
	'recipe-alternate-polymer-resin' = 41,
	'recipe-alternate-pure-caterium-ingot' = 42,
	'recipe-alternate-pure-copper-ingot' = 43,
	'recipe-alternate-pure-iron-ingot' = 44,
	'recipe-alternate-pure-quartz-crystal' = 45,
	'recipe-alternate-recycled-rubber' = 46,
	'recipe-alternate-rubber-concrete' = 47,
	'recipe-alternate-steamed-copper-sheet' = 48,
	'recipe-alternate-steel-canister' = 49,
	'recipe-alternate-steel-coated-plate' = 50,
	'recipe-alternate-steel-rod' = 51,
	'recipe-alternate-turbo-heavy-fuel' = 52,
	'recipe-alternate-wet-concrete' = 53,
	'recipe-pure-aluminum-ingot' = 54,
	'recipe-alternate-beacon-1' = 55,
	'recipe-alternate-cable-1' = 56,
	'recipe-alternate-cable-2' = 57,
	'recipe-alternate-circuit-board-1' = 58,
	'recipe-alternate-circuit-board-2' = 59,
	'recipe-alternate-coal-1' = 60,
	'recipe-alternate-coal-2' = 61,
	'recipe-alternate-computer-1' = 62,
	'recipe-alternate-computer-2' = 63,
	'recipe-alternate-concrete' = 64,
	'recipe-alternate-crystal-oscillator' = 65,
	'recipe-alternate-electromagnetic-control-rod-1' = 66,
	'recipe-alternate-encased-industrial-beam' = 67,
	'recipe-alternate-enriched-coal' = 68,
	'recipe-alternate-gunpowder-1' = 69,
	'recipe-alternate-heat-sink-1' = 70,
	'recipe-alternate-high-speed-connector' = 71,
	'recipe-alternate-ingot-iron' = 72,
	'recipe-alternate-ingot-steel-1' = 73,
	'recipe-alternate-ingot-steel-2' = 74,
	'recipe-alternate-modular-frame' = 75,
	'recipe-alternate-modular-frame-heavy' = 76,
	'recipe-alternate-motor-1' = 77,
	'recipe-alternate-nobelisk-1' = 78,
	'recipe-alternate-nuclear-fuel-rod-1' = 79,
	'recipe-alternate-plastic-1' = 80,
	'recipe-alternate-quickwire' = 81,
	'recipe-alternate-radio-control-unit-1' = 82,
	'recipe-alternate-reinforced-iron-plate-1' = 83,
	'recipe-alternate-reinforced-iron-plate-2' = 84,
	'recipe-alternate-rotor' = 85,
	'recipe-alternate-screw' = 86,
	'recipe-alternate-screw-2' = 87,
	'recipe-alternate-silica' = 88,
	'recipe-alternate-stator' = 89,
	'recipe-alternate-turbofuel' = 90,
	'recipe-alternate-turbo-motor-1' = 91,
	'recipe-alternate-uranium-cell-1' = 92,
	'recipe-alternate-wire-1' = 93,
	'recipe-alternate-wire-2' = 94,
	'recipe-ailimiter' = 95,
	'recipe-circuit-board' = 96,
	'recipe-electromagnetic-control-rod' = 97,
	'recipe-encased-industrial-beam' = 98,
	'recipe-heat-sink' = 99,
	'recipe-iron-plate-reinforced' = 100,
	'recipe-modular-frame' = 101,
	'recipe-motor' = 102,
	'recipe-rotor' = 103,
	'recipe-stator' = 104,
	'recipe-uranium-cell' = 105,
	'recipe-fence-01' = 106,
	'recipe-foundation-8x1-01' = 107,
	'recipe-foundation-8x2-01' = 108,
	'recipe-foundation-8x4-01' = 109,
	'recipe-foundation-frame-01' = 110,
	'recipe-foundation-glass-01' = 111,
	'recipe-pillar-base' = 112,
	'recipe-pillar-middle' = 113,
	'recipe-pillar-top' = 114,
	'recipe-quarter-pipe' = 115,
	'recipe-quarter-pipe-02' = 116,
	'recipe-quarter-pipe-corner-01' = 117,
	'recipe-quarter-pipe-corner-02' = 118,
	'recipe-quarter-pipe-corner-03' = 119,
	'recipe-quarter-pipe-corner-04' = 120,
	'recipe-ladder' = 121,
	'recipe-ramp-8x1-01' = 122,
	'recipe-ramp-8x2-01' = 123,
	'recipe-ramp-8x4-01' = 124,
	'recipe-ramp-8x4-inverted-01' = 125,
	'recipe-ramp-8x8x8' = 126,
	'recipe-ramp-diagonal-8x1-01' = 127,
	'recipe-ramp-diagonal-8x1-02' = 128,
	'recipe-ramp-diagonal-8x2-01' = 129,
	'recipe-ramp-diagonal-8x2-02' = 130,
	'recipe-ramp-diagonal-8x4-01' = 131,
	'recipe-ramp-diagonal-8x4-02' = 132,
	'recipe-ramp-double' = 133,
	'recipe-ramp-double-8x1' = 134,
	'recipe-ramp-inverted-8x1' = 135,
	'recipe-ramp-inverted-8x1-corner-01' = 136,
	'recipe-ramp-inverted-8x1-corner-02' = 137,
	'recipe-ramp-inverted-8x2-01' = 138,
	'recipe-ramp-inverted-8x2-corner-01' = 139,
	'recipe-ramp-inverted-8x2-corner-02' = 140,
	'recipe-ramp-inverted-8x4-corner-01' = 141,
	'recipe-ramp-inverted-8x4-corner-02' = 142,
	'recipe-assembler-mk1' = 143,
	'recipe-constructor-mk1' = 144,
	'recipe-conveyor-attachment-merger' = 145,
	'recipe-conveyor-attachment-splitter' = 146,
	'recipe-conveyor-attachment-splitter-programmable' = 147,
	'recipe-conveyor-attachment-splitter-smart' = 148,
	'recipe-conveyor-belt-mk1' = 149,
	'recipe-conveyor-belt-mk2' = 150,
	'recipe-conveyor-belt-mk3' = 151,
	'recipe-conveyor-belt-mk4' = 152,
	'recipe-conveyor-belt-mk5' = 153,
	'recipe-conveyor-lift-mk1' = 154,
	'recipe-conveyor-lift-mk2' = 155,
	'recipe-conveyor-lift-mk3' = 156,
	'recipe-conveyor-lift-mk4' = 157,
	'recipe-conveyor-lift-mk5' = 158,
	'recipe-conveyor-pole' = 159,
	'recipe-conveyor-pole-stackable' = 160,
	'recipe-conveyor-pole-wall' = 161,
	'recipe-generator-biomass' = 162,
	'recipe-generator-coal' = 163,
	'recipe-generator-fuel' = 164,
	'recipe-generator-geo-thermal' = 165,
	'recipe-generator-integrated-biomass' = 166,
	'recipe-generator-nuclear' = 167,
	'recipe-hub-terminal' = 168,
	'recipe-hyper-pole-stackable' = 169,
	'recipe-hyper-tube-wall-hole' = 170,
	'recipe-hyper-tube-wall-support' = 171,
	'recipe-industrial-tank' = 172,
	'recipe-jump-pad' = 173,
	'recipe-jump-pad-adjustable' = 174,
	'recipe-jump-pad-tilted' = 175,
	'recipe-lookout-tower' = 176,
	'recipe-mam' = 177,
	'recipe-manufacturer-mk1' = 178,
	'recipe-miner-mk1' = 179,
	'recipe-miner-mk2' = 180,
	'recipe-miner-mk3' = 181,
	'recipe-oil-pump' = 182,
	'recipe-oil-refinery' = 183,
	'recipe-packager' = 184,
	'recipe-pipe-hyper' = 185,
	'recipe-pipe-hyper-support' = 186,
	'recipe-pipeline' = 187,
	'recipe-pipeline-junction-cross' = 188,
	'recipe-pipeline-mk2' = 189,
	'recipe-pipeline-pump' = 190,
	'recipe-pipeline-pump-mk2' = 191,
	'recipe-pipe-storage-tank' = 192,
	'recipe-pipe-support' = 193,
	'recipe-pipe-support-stackable' = 194,
	'recipe-pipe-support-wall' = 195,
	'recipe-pipe-support-wall-hole' = 196,
	'recipe-power-line' = 197,
	'recipe-power-pole-mk1' = 198,
	'recipe-power-pole-mk2' = 199,
	'recipe-power-pole-mk3' = 200,
	'recipe-power-pole-wall' = 201,
	'recipe-power-pole-wall-double' = 202,
	'recipe-power-pole-wall-double-mk2' = 203,
	'recipe-power-pole-wall-double-mk3' = 204,
	'recipe-power-pole-wall-mk2' = 205,
	'recipe-power-pole-wall-mk3' = 206,
	'recipe-radar-tower' = 207,
	'recipe-railroad-track' = 208,
	'recipe-railroad-track-integrated' = 209,
	'recipe-resource-sink' = 210,
	'recipe-resource-sink-shop' = 211,
	'recipe-smelter-basic-mk1' = 212,
	'recipe-smelter-mk1' = 213,
	'recipe-storage-container-mk1' = 214,
	'recipe-storage-container-mk2' = 215,
	'recipe-storage-integrated' = 216,
	'recipe-storage-player' = 217,
	'recipe-trading-post' = 218,
	'recipe-truck-station' = 219,
	'recipe-ujelly-landing-pad' = 220,
	'recipe-valve' = 221,
	'recipe-water-pump' = 222,
	'recipe-work-bench' = 223,
	'recipe-work-bench-integrated' = 224,
	'recipe-workshop' = 225,
	'recipe-stair-1b' = 226,
	'recipe-stairs-left-01' = 227,
	'recipe-stairs-right-01' = 228,
	'recipe-space-elevator' = 229,
	'recipe-walkway-cross' = 230,
	'recipe-walkway-ramp' = 231,
	'recipe-walkway-straight' = 232,
	'recipe-walkway-t' = 233,
	'recipe-walkway-turn' = 234,
	'recipe-wall-8x4-01' = 235,
	'recipe-wall-8x4-02' = 236,
	'recipe-wall-conveyor-8x4-01' = 237,
	'recipe-wall-conveyor-8x4-01-steel' = 238,
	'recipe-wall-conveyor-8x4-02' = 239,
	'recipe-wall-conveyor-8x4-02-steel' = 240,
	'recipe-wall-conveyor-8x4-03' = 241,
	'recipe-wall-conveyor-8x4-03-steel' = 242,
	'recipe-wall-conveyor-8x4-04' = 243,
	'recipe-wall-conveyor-8x4-04-steel' = 244,
	'recipe-wall-door-8x4-01' = 245,
	'recipe-wall-door-8x4-01-steel' = 246,
	'recipe-wall-door-8x4-02' = 247,
	'recipe-wall-door-8x4-02-steel' = 248,
	'recipe-wall-door-8x4-03' = 249,
	'recipe-wall-door-8x4-03-steel' = 250,
	'recipe-wall-gate-8x4-01' = 251,
	'recipe-wall-window-8x4-01' = 252,
	'recipe-wall-window-8x4-02' = 253,
	'recipe-wall-window-8x4-03' = 254,
	'recipe-wall-window-8x4-03-steel' = 255,
	'recipe-wall-window-8x4-04' = 256,
	'recipe-aluminum-sheet' = 257,
	'recipe-biofuel' = 258,
	'recipe-biomass-alien-carapace' = 259,
	'recipe-biomass-alien-organs' = 260,
	'recipe-biomass-leaves' = 261,
	'recipe-biomass-mycelia' = 262,
	'recipe-biomass-wood' = 263,
	'recipe-cable' = 264,
	'recipe-concrete' = 265,
	'recipe-copper-sheet' = 266,
	'recipe-fabric' = 267,
	'recipe-fluid-canister' = 268,
	'recipe-iron-plate' = 269,
	'recipe-iron-rod' = 270,
	'recipe-nuclear-fuel-rod' = 271,
	'recipe-power-crystal-shard-1' = 272,
	'recipe-power-crystal-shard-2' = 273,
	'recipe-power-crystal-shard-3' = 274,
	'recipe-quartz-crystal' = 275,
	'recipe-quickwire' = 276,
	'recipe-screw' = 277,
	'recipe-silica' = 278,
	'recipe-steel-beam' = 279,
	'recipe-steel-pipe' = 280,
	'recipe-wire' = 281,
	'recipe-beacon' = 282,
	'recipe-blade-runners' = 283,
	'recipe-candy-cane-basher' = 284,
	'recipe-cartridge' = 285,
	'recipe-chainsaw' = 286,
	'recipe-color-cartridge' = 287,
	'recipe-color-gun' = 288,
	'recipe-filter-gas-mask' = 289,
	'recipe-filter-hazmat' = 290,
	'recipe-gasmask' = 291,
	'recipe-gunpowder' = 292,
	'recipe-hazmat-suit' = 293,
	'recipe-jet-pack' = 294,
	'recipe-medicinal-inhaler' = 295,
	'recipe-medicinal-inhaler-alien-organs' = 296,
	'recipe-nobelisk' = 297,
	'recipe-nobelisk-detonator' = 298,
	'recipe-nutritional-inhaler' = 299,
	'recipe-object-scanner' = 300,
	'recipe-parachute' = 301,
	'recipe-portable-miner' = 302,
	'recipe-rebar-gun' = 303,
	'recipe-space-rifle-mk1' = 304,
	'recipe-spiked-rebar' = 305,
	'recipe-xeno-basher' = 306,
	'recipe-xeno-zapper' = 307,
	'recipe-snowball' = 308,
	'recipe-snowball-weapon' = 309,
	'recipe-tree-gift-producer' = 310,
	'recipe-battery' = 311,
	'recipe-computer' = 312,
	'recipe-computer-super' = 313,
	'recipe-crystal-oscillator' = 314,
	'recipe-high-speed-connector' = 315,
	'recipe-modular-frame-heavy' = 316,
	'recipe-motor-turbo' = 317,
	'recipe-radio-control-unit' = 318,
	'recipe-alumina-solution' = 319,
	'recipe-aluminum-scrap' = 320,
	'recipe-fuel' = 321,
	'recipe-liquid-biofuel' = 322,
	'recipe-liquid-fuel' = 323,
	'recipe-packaged-biofuel' = 324,
	'recipe-packaged-crude-oil' = 325,
	'recipe-packaged-oil-residue' = 326,
	'recipe-packaged-turbo-fuel' = 327,
	'recipe-packaged-water' = 328,
	'recipe-petroleum-coke' = 329,
	'recipe-plastic' = 330,
	'recipe-residual-fuel' = 331,
	'recipe-residual-plastic' = 332,
	'recipe-residual-rubber' = 333,
	'recipe-rubber' = 334,
	'recipe-sulfuric-acid' = 335,
	'recipe-unpackage-bio-fuel' = 336,
	'recipe-unpackage-fuel' = 337,
	'recipe-unpackage-oil' = 338,
	'recipe-unpackage-oil-residue' = 339,
	'recipe-unpackage-turbo-fuel' = 340,
	'recipe-unpackage-water' = 341,
	'recipe-uranium-pellet' = 342,// deprecated
	'recipe-coal' = 343,
	'recipe-crude-oil' = 344,
	'recipe-limestone' = 345,
	'recipe-ore-bauxite' = 346,
	'recipe-ore-caterium' = 347,
	'recipe-ore-copper' = 348,
	'recipe-ore-iron' = 349,
	'recipe-ore-uranium' = 350,
	'recipe-raw-quartz' = 351,
	'recipe-sulfur' = 352,
	'recipe-ingot-aluminum' = 353,
	'recipe-ingot-caterium' = 354,
	'recipe-ingot-copper' = 355,
	'recipe-ingot-iron' = 356,
	'recipe-ingot-steel' = 357,
	'recipe-space-elevator-part-1' = 358,
	'recipe-space-elevator-part-2' = 359,
	'recipe-space-elevator-part-3' = 360,
	'recipe-space-elevator-part-4' = 361,
	'recipe-space-elevator-part-5' = 362,
	'recipe-cyber-wagon' = 363,
	'recipe-explorer' = 364,
	'recipe-factory-cart' = 365,
	'recipe-tractor' = 366,
	'recipe-truck' = 367,
	'recipe-freight-wagon' = 368,
	'recipe-locomotive' = 369,
	'recipe-alternate-alclad-casing' = 370,
	'recipe-alternate-automated-miner' = 371,
	'recipe-alternate-classic-battery' = 372,
	'recipe-alternate-cooling-device' = 373,
	'recipe-alternate-diluted-fuel' = 374,
	'recipe-alternate-electric-motor' = 375,
	'recipe-alternate-fertile-uranium' = 376,
	'recipe-alternate-heat-fused-frame' = 377,
	'recipe-alternate-instant-plutonium-cell' = 378,
	'recipe-alternate-instant-scrap' = 379,
	'recipe-alternate-ocsupercomputer' = 380,
	'recipe-alternate-plutonium-fuel-unit' = 381,
	'recipe-alternate-radio-control-system' = 382,
	'recipe-alternate-sloppy-alumina' = 383,
	'recipe-alternate-super-state-computer' = 384,
	'recipe-alternate-turbo-blend-fuel' = 385,
	'recipe-alternate-turbo-pressure-motor' = 386,
	'recipe-plutonium-cell' = 387,
	'recipe-pressure-conversion-cube' = 388,
	'recipe-cooling-system' = 389,
	'recipe-fused-modular-frame' = 390,
	'recipe-nitric-acid' = 391,
	'recipe-non-fissile-uranium' = 392,
	'recipe-blender' = 393,
	'recipe-ceiling-light' = 394,
	'recipe-drone-station' = 395,
	'recipe-drone-transport' = 396,
	'recipe-floodlight-pole' = 397,
	'recipe-floodlight-wall' = 398,
	'recipe-fracking-extractor' = 399,
	'recipe-fracking-smasher' = 400,
	'recipe-hadron-collider' = 401,
	'recipe-lights-control-panel' = 402,
	'recipe-power-storage-mk1' = 403,
	'recipe-power-switch' = 404,
	'recipe-railroad-switch-control' = 405,
	'recipe-street-light' = 406,
	'recipe-aluminum-casing' = 407,
	'recipe-copper-dust' = 408,
	'recipe-gas-tank' = 409,
	'recipe-hoverpack' = 410,
	'recipe-zip-line' = 411,
	'recipe-plutonium' = 412,
	'recipe-plutonium-fuel-rod' = 413,
	'recipe-packaged-alumina' = 414,
	'recipe-packaged-nitric-acid' = 415,
	'recipe-packaged-nitrogen' = 416,
	'recipe-packaged-sulfuric-acid' = 417,
	'recipe-unpackage-alumina' = 418,
	'recipe-unpackage-nitric-acid' = 419,
	'recipe-unpackage-nitrogen' = 420,
	'recipe-unpackage-sulfuric-acid' = 421,
	'recipe-space-elevator-part-6' = 422,
	'recipe-space-elevator-part-7' = 423,
	'recipe-space-elevator-part-8' = 424,
	'recipe-space-elevator-part-9' = 425,
}

export enum itemEnums {
	'item-modular-frame-heavy' = 1,
	'item-any-undefined' = 2,
	'item-fence-01' = 3,
	'item-foundation-8x1-01' = 4,
	'item-foundation-8x2-01' = 5,
	'item-foundation-8x4-01' = 6,
	'item-foundation-frame-01' = 7,
	'item-foundation-glass-01' = 8,
	'item-pillar-base' = 9,
	'item-pillar-middle' = 10,
	'item-pillar-top' = 11,
	'item-quarter-pipe' = 12,
	'item-quarter-pipe-02' = 13,
	'item-quarter-pipe-corner-01' = 14,
	'item-quarter-pipe-corner-02' = 15,
	'item-quarter-pipe-corner-03' = 16,
	'item-quarter-pipe-corner-04' = 17,
	'item-ladder' = 18,
	'item-ramp-8x1-01' = 19,
	'item-ramp-8x2-01' = 20,
	'item-ramp-8x4-01' = 21,
	'item-ramp-8x4-inverted-01' = 22,
	'item-ramp-8x8x8' = 23,
	'item-ramp-diagonal-8x1-01' = 24,
	'item-ramp-diagonal-8x1-02' = 25,
	'item-ramp-diagonal-8x2-01' = 26,
	'item-ramp-diagonal-8x2-02' = 27,
	'item-ramp-diagonal-8x4-01' = 28,
	'item-ramp-diagonal-8x4-02' = 29,
	'item-ramp-double' = 30,
	'item-ramp-double-8x1' = 31,
	'item-ramp-inverted-8x1' = 32,
	'item-ramp-inverted-8x1-corner-01' = 33,
	'item-ramp-inverted-8x1-corner-02' = 34,
	'item-ramp-inverted-8x2-01' = 35,
	'item-ramp-inverted-8x2-corner-01' = 36,
	'item-ramp-inverted-8x2-corner-02' = 37,
	'item-ramp-inverted-8x4-corner-01' = 38,
	'item-ramp-inverted-8x4-corner-02' = 39,
	'item-stair-1b' = 40,
	'item-stairs-left-01' = 41,
	'item-stairs-right-01' = 42,
	'item-walkway-cross' = 43,
	'item-walkway-ramp' = 44,
	'item-walkway-straight' = 45,
	'item-walkway-t' = 46,
	'item-walkway-turn' = 47,
	'item-wall-8x4-01' = 48,
	'item-wall-8x4-02' = 49,
	'item-wall-conveyor-8x4-01' = 50,
	'item-wall-conveyor-8x4-01-steel' = 51,
	'item-wall-conveyor-8x4-02' = 52,
	'item-wall-conveyor-8x4-02-steel' = 53,
	'item-wall-conveyor-8x4-03' = 54,
	'item-wall-conveyor-8x4-03-steel' = 55,
	'item-wall-conveyor-8x4-04' = 56,
	'item-wall-conveyor-8x4-04-steel' = 57,
	'item-wall-door-8x4-01' = 58,
	'item-wall-door-8x4-01-steel' = 59,
	'item-wall-door-8x4-02' = 60,
	'item-wall-door-8x4-02-steel' = 61,
	'item-wall-door-8x4-03' = 62,
	'item-wall-door-8x4-03-steel' = 63,
	'item-wall-gate-8x4-01' = 64,
	'item-wall-window-8x4-01' = 65,
	'item-wall-window-8x4-02' = 66,
	'item-wall-window-8x4-03' = 67,
	'item-wall-window-8x4-03-steel' = 68,
	'item-wall-window-8x4-04' = 69,
	'item-assembler-mk1' = 70,
	'item-conveyor-attachment-merger' = 71,
	'item-conveyor-attachment-splitter' = 72,
	'item-conveyor-attachment-splitter-programmable' = 73,
	'item-conveyor-attachment-splitter-smart' = 74,
	'item-constructor-mk1' = 75,
	'item-conveyor-belt-mk1' = 76,
	'item-conveyor-belt-mk2' = 77,
	'item-conveyor-belt-mk3' = 78,
	'item-conveyor-belt-mk4' = 79,
	'item-conveyor-belt-mk5' = 80,
	'item-conveyor-lift-mk1' = 81,
	'item-conveyor-lift-mk2' = 82,
	'item-conveyor-lift-mk3' = 83,
	'item-conveyor-lift-mk4' = 84,
	'item-conveyor-lift-mk5' = 85,
	'item-conveyor-pole-wall' = 86,
	'item-foundry-mk1' = 87,
	'item-generator-biomass' = 88,
	'item-generator-integrated-biomass' = 89,
	'item-generator-coal' = 90,
	'item-generator-fuel' = 91,
	'item-generator-geo-thermal' = 92,
	'item-generator-nuclear' = 93,
	'item-tree-gift-producer' = 94,
	'item-hub-terminal' = 95,
	'item-hyper-tube-wall-hole' = 96,
	'item-hyper-tube-wall-support' = 97,
	'item-industrial-tank' = 98,
	'item-jump-pad' = 99,
	'item-jump-pad-adjustable' = 100,
	'item-jump-pad-tilted' = 101,
	'item-landing-pad' = 102,
	'item-lookout-tower' = 103,
	'item-mam' = 104,
	'item-manufacturer-mk1' = 105,
	'item-miner-mk1' = 106,
	'item-miner-mk2' = 107,
	'item-miner-mk3' = 108,
	'item-oil-pump' = 109,
	'item-oil-refinery' = 110,
	'item-packager' = 111,
	'item-pipe-hyper' = 112,
	'item-pipe-hyper-start' = 113,
	'item-pipeline-junction-cross' = 114,
	'item-pipeline' = 115,
	'item-pipeline-mk2' = 116,
	'item-pipeline-support-wall' = 117,
	'item-pipeline-support-wall-hole' = 118,
	'item-pipeline-pump' = 119,
	'item-pipeline-pump-mk2' = 120,
	'item-valve' = 121,
	'item-power-line' = 122,
	'item-power-pole-mk1' = 123,
	'item-power-pole-mk2' = 124,
	'item-power-pole-mk3' = 125,
	'item-power-pole-wall' = 126,
	'item-power-pole-wall-mk2' = 127,
	'item-power-pole-wall-mk3' = 128,
	'item-power-pole-wall-double' = 129,
	'item-power-pole-wall-double-mk2' = 130,
	'item-power-pole-wall-double-mk3' = 131,
	'item-radar-tower' = 132,
	'item-resource-sink' = 133,
	'item-resource-sink-shop' = 134,
	'item-smelter-mk1' = 135,
	'item-space-elevator' = 136,
	'item-storage-container-mk1' = 137,
	'item-storage-container-mk2' = 138,
	'item-storage-integrated' = 139,
	'item-storage-player' = 140,
	'item-pipe-storage-tank' = 141,
	'item-trading-post' = 142,
	'item-train-docking-station' = 143,
	'item-train-docking-station-liquid' = 144,
	'item-train-platform-empty' = 145,
	'item-train-station' = 146,
	'item-railroad-track' = 147,
	'item-railroad-track-integrated' = 148,
	'item-truck-station' = 149,
	'item-water-pump' = 150,
	'item-work-bench' = 151,
	'item-work-bench-integrated' = 152,
	'item-workshop' = 153,
	'item-candy-cane-decor' = 154,
	'item-xmass-lights' = 155,
	'item-snow-dispenser' = 156,
	'item-snowman' = 157,
	'item-xmass-tree' = 158,
	'item-wreath-decor' = 159,
	'item-health-gain-descriptor' = 160,
	'item-berry' = 161,
	'item-shroom' = 162,
	'item-nut' = 163,
	'item-equipment-descriptor-beacon' = 164,
	'item-parachute' = 165,
	'item-medkit' = 166,
	'item-character-clap-statue' = 167,
	'item-character-run-statue' = 168,
	'item-character-spin-statue' = 169,
	'item-doggo-statue' = 170,
	'item-golden-nut-statue' = 171,
	'item-hog-statue' = 172,
	'item-space-giraffe-statue' = 173,
	'item-chainsaw' = 174,
	'item-equipment-descriptor-build-gun' = 175,
	'item-equipment-descriptor-color-gun' = 176,
	'item-equipment-descriptor-cup' = 177,
	'item-dowsing-stick' = 178,
	'item-equipment-descriptor-gasmask' = 179,
	'item-equipment-descriptor-object-scanner' = 180,
	'item-golf-cart' = 181,
	'item-equipment-descriptor-hazard-suit' = 182,// deprecated
	'item-equipment-descriptor-hazmat-suit' = 183,
	'item-equipment-descriptor-snowball-mittens' = 184,
	'item-equipment-descriptor-jet-pack' = 185,
	'item-equipment-descriptor-jet-pack-mk2' = 186,
	'item-equipment-descriptor-jumping-stilts' = 187,
	'item-equipment-descriptor-machinegun' = 188,
	'item-rebar-gun' = 189,
	'item-rebar-gun-projectile' = 190,
	'item-equipment-descriptor-nobelisk-detonator' = 191,
	'item-item-descriptor-portable-miner' = 192,
	'item-rebar-scatter-gun-projectile' = 193,
	'item-equipment-descriptor-resource-miner' = 194,
	'item-equipment-descriptor-rifle' = 195,
	'item-equipment-descriptor-rifle-mk2' = 196,
	'item-equipment-descriptor-shock-shank' = 197,
	'item-equipment-descriptor-candy-cane' = 198,
	'item-equipment-descriptor-stun-spear' = 199,
	'item-tool-belt' = 200,
	'item-candy-cane' = 201,
	'item-gift' = 202,
	'item-snow' = 203,
	'item-xmas-ball1' = 204,
	'item-xmas-ball2' = 205,
	'item-xmas-ball3' = 206,
	'item-xmas-ball4' = 207,
	'item-xmas-ball-cluster' = 208,
	'item-xmas-bow' = 209,
	'item-xmas-branch' = 210,
	'item-xmas-lights' = 211,
	'item-xmas-star' = 212,
	'item-xmas-wreath' = 213,
	'item-wat1' = 214,
	'item-wat2' = 215,
	'item-hard-drive' = 216,
	'item-server-rack' = 217,
	'item-crystal' = 218,
	'item-crystal-mk2' = 219,
	'item-crystal-mk3' = 220,
	'item-crystal-shard' = 221,
	'item-alumina-solution' = 222,
	'item-aluminum-ingot' = 223,
	'item-aluminum-plate' = 224,
	'item-aluminum-plate-reinforced' = 225,
	'item-aluminum-scrap' = 226,
	'item-battery' = 227,
	'item-cable' = 228,
	'item-cartridge-standard' = 229,
	'item-cement' = 230,
	'item-circuit-board' = 231,
	'item-circuit-board-high-speed' = 232,
	'item-color-cartridge' = 233,
	'item-compacted-coal' = 234,
	'item-computer' = 235,
	'item-computer-quantum' = 236,
	'item-computer-super' = 237,
	'item-copper-ingot' = 238,
	'item-copper-sheet' = 239,
	'item-crystal-oscillator' = 240,
	'item-dark-matter' = 241,
	'item-electromagnetic-control-rod' = 242,
	'item-filter' = 243,
	'item-fluid-canister' = 244,
	'item-fuel' = 245,
	'item-liquid-fuel' = 246,
	'item-gold-ingot' = 247,
	'item-gunpowder' = 248,
	'item-heavy-oil-residue' = 249,
	'item-packaged-oil-residue' = 250,
	'item-high-speed-connector' = 251,
	'item-high-speed-wire' = 252,
	'item-hubparts' = 253,
	'item-hazmat-filter' = 254,
	'item-iron-ingot' = 255,
	'item-iron-plate' = 256,
	'item-iron-plate-reinforced' = 257,
	'item-iron-rod' = 258,
	'item-iron-screw' = 259,
	'item-modular-frame' = 260,
	'item-modular-frame-lightweight' = 261,
	'item-motor' = 262,
	'item-motor-lightweight' = 263,
	'item-nobelisk-explosive' = 264,
	'item-nuclear-waste' = 265,
	'item-petroleum-coke' = 266,
	'item-plastic' = 267,
	'item-polymer-resin' = 268,
	'item-quantum-crystal' = 269,
	'item-quantum-oscillator' = 270,
	'item-quartz-crystal' = 271,
	'item-resource-sink-coupon' = 272,
	'item-rotor' = 273,
	'item-rubber' = 274,
	'item-samfluctuator' = 275,
	'item-samingot' = 276,
	'item-silica' = 277,
	'item-snowball-projectile' = 278,
	'item-space-elevator-blocker' = 279,
	'item-space-elevator-part-1' = 280,
	'item-space-elevator-part-2' = 281,
	'item-space-elevator-part-3' = 282,
	'item-space-elevator-part-4' = 283,
	'item-space-elevator-part-5' = 284,
	'item-space-elevator-part-6' = 285,
	'item-space-elevator-part-7' = 286,
	'item-space-elevator-part-8' = 287,
	'item-spiked-rebar' = 288,
	'item-spiked-rebar-scatter' = 289,
	'item-stator' = 290,
	'item-steel-ingot' = 291,
	'item-steel-pipe' = 292,
	'item-steel-plate' = 293,
	'item-steel-plate-reinforced' = 294,
	'item-sulfuric-acid' = 295,
	'item-liquid-turbo-fuel' = 296,
	'item-turbo-fuel' = 297,
	'item-uranium-cell' = 298,
	'item-uranium-pellet' = 299,
	'item-wire' = 300,
	'item-packaged-oil' = 301,
	'item-packaged-water' = 302,
	'item-hog-parts' = 303,
	'item-spitter-parts' = 304,
	'item-biofuel' = 305,
	'item-liquid-biofuel' = 306,
	'item-packaged-biofuel' = 307,
	'item-fabric' = 308,
	'item-flower-petals' = 309,
	'item-generic-biomass' = 310,
	'item-leaves' = 311,
	'item-mycelia' = 312,
	'item-pigment' = 313,
	'item-vines' = 314,
	'item-wood' = 315,
	'item-nuclear-fuel-rod' = 316,
	'item-none' = 317,
	'item-overflow' = 318,
	'item-conveyor-pole' = 319,
	'item-conveyor-pole-stackable' = 320,
	'item-pipe-hyper-support' = 321,
	'item-hyper-pole-stackable' = 322,
	'item-pipeline-support' = 323,
	'item-pipe-support-stackable' = 324,
	'item-coal' = 325,
	'item-liquid-oil' = 326,
	'item-ore-bauxite' = 327,
	'item-ore-copper' = 328,
	'item-ore-gold' = 329,
	'item-ore-iron' = 330,
	'item-ore-uranium' = 331,
	'item-raw-quartz' = 332,
	'item-sam' = 333,
	'item-stone' = 334,
	'item-sulfur' = 335,
	'item-water' = 336,
	'item-geyser' = 337,
	'item-cyber-wagon' = 338,
	'item-explorer' = 339,
	'item-tractor' = 340,
	'item-locomotive' = 341,
	'item-freight-wagon' = 342,
	'item-truck' = 343,
	'item-wildcard' = 344,
	'item-blender' = 345, // new value
	'item-ceiling-light' = 346, // new value
	'item-drone-station' = 347, // new value
	'item-floodlight-pole' = 348, // new value
	'item-floodlight-wall' = 349, // new value
	'item-fracking-extractor' = 350, // new value
	'item-fracking-smasher' = 351, // new value
	'item-hadron-collider' = 352, // new value
	'item-lights-control-panel' = 353, // new value
	'item-power-storage-mk1' = 354, // new value
	'item-power-switch' = 355, // new value
	'item-street-light' = 356, // new value
	'item-railroad-switch-control' = 357, // new value
	'item-berry-bush' = 358, // new value
	'item-nut-bush' = 359, // new value
	'item-boom-box' = 360, // new value
	'item-equipment-descriptor-cup-gold' = 361, // new value
	'item-equipment-descriptor-hover-pack' = 362, // new value
	'item-eq-desc-zip-line' = 363, // new value
	'item-battery-charged' = 364, // new value
	'item-aluminum-casing' = 365, // new value
	'item-cooling-system' = 366, // new value
	'item-copper-dust' = 367, // new value
	'item-gas-tank' = 368, // new value
	'item-hydrogen-gas' = 369, // new value
	'item-modular-frame-fused' = 370, // new value
	'item-natural-gas' = 371, // new value
	'item-nitric-acid' = 372, // new value
	'item-non-fissible-uranium' = 373, // new value
	'item-plutonium-waste' = 374, // new value
	'item-plutonium-cell' = 375, // new value
	'item-plutonium-pellet' = 376, // new value
	'item-pressure-conversion-cube' = 377, // new value
	'item-propane-gas' = 378, // new value
	'item-rocket-fuel' = 379, // new value
	'item-space-elevator-part-9' = 380, // new value
	'item-volcanic-gas' = 381, // new value
	'item-packaged-alumina' = 382, // new value
	'item-packaged-nitric-acid' = 383, // new value
	'item-packaged-nitrogen-gas' = 384, // new value
	'item-packaged-sulfuric-acid' = 385, // new value
	'item-plutonium-fuel-rod' = 386, // new value
	'item-nitrogen-gas' = 387, // new value
	'item-absolute-hits94' = 388, // new value
	'item-child-playing-piano' = 389, // new value
	'item-lasse-stefans-ballader' = 390, // new value
	'item-drone-transport' = 391, // new value
}
