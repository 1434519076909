export enum EDistanceCullCategory {
  FGDDC_NEVERCULL,
  FGDDC_Gameplay,
  FGDCC_BuildingSmall,
  FGDCC_BuildingMedium,
  FGDCC_BuildingBig,
  FGDCC_BuildingSupports,
  FGDCC_Infrastructure,
  FGDDC_ProductionIndicators,
  FGDDC_Decor,
  FGDDC_MAX,
}

export const EDistanceCullCategoryDisplayName = {
  [EDistanceCullCategory.FGDDC_NEVERCULL]: 'Never distance cull',
  [EDistanceCullCategory.FGDDC_Gameplay]: 'Buildings - Gameplay(NEVER CULL)',
  [EDistanceCullCategory.FGDCC_BuildingSmall]: 'Building - Small',
  [EDistanceCullCategory.FGDCC_BuildingMedium]: 'Building - Medium',
  [EDistanceCullCategory.FGDCC_BuildingBig]: 'Building - Big',
  [EDistanceCullCategory.FGDCC_BuildingSupports]: 'Building Supports',
  [EDistanceCullCategory.FGDCC_Infrastructure]: 'infrastructure',
  [EDistanceCullCategory.FGDDC_ProductionIndicators]: 'Production Indicator',
  [EDistanceCullCategory.FGDDC_Decor]: 'Decor',
  [EDistanceCullCategory.FGDDC_MAX]: '<FGDDC_MAX>',
};
