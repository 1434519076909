/**
 * Flags
 */
export enum EPoolInstanceFlags {
  None,
  Dirty_EnvironmentContext,
  Dirty_ColorState,
  Dirty_ForceStateOff,
  Dirty_StateOn,
  Dirty_Scalability,
}
