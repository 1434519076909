export enum ECustomMovementMode {
  CMM_None,
  CMM_Ladder,
  CMM_PipeHyper,
  CMM_Zipline,

  /**
   * Three hover modes to better represent the "current" hover state with the SavedMove system
   */
  CMM_Hover,
  CMM_HoverSlowFall,
}

export const ECustomMovementModeDisplayName = {
  [ECustomMovementMode.CMM_None]: 'None',
  [ECustomMovementMode.CMM_Ladder]: 'Ladder',
  [ECustomMovementMode.CMM_PipeHyper]: 'Hyper Pipe',
  [ECustomMovementMode.CMM_Zipline]: 'Zipline',
  [ECustomMovementMode.CMM_Hover]: 'Hover',
  [ECustomMovementMode.CMM_HoverSlowFall]: 'Hover Slow Fall',
};
