export enum EOptionType {
  OT_Checkbox,
  OT_Slider,
  OT_IntegerSelection,
  OT_FloatSelection,
  OT_Custom,
}

export const EOptionTypeDisplayName = {
  [EOptionType.OT_Checkbox]: 'Checkbox',
  [EOptionType.OT_Slider]: 'Slider',
  [EOptionType.OT_IntegerSelection]: 'IntegerSelection',
  [EOptionType.OT_FloatSelection]: 'FloatSelection',
  [EOptionType.OT_Custom]: 'Custom',
};
