export enum EDroneStatus {
  EDS_NO_DRONE,
  EDS_DOCKED,
  EDS_LOADING,
  EDS_TAKEOFF,
  EDS_EN_ROUTE,
  EDS_DOCKING,
  EDS_UNLOADING,
  EDS_NOT_ENOUGH_BATTERIES,
  EDS_CANNOT_UNLOAD,
}
