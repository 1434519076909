export enum EOptionGamemodeType {
  OGT_Always,
  OGT_OnlyInMainMenu,
  OGT_OnlyInGame,
}

export const EOptionGamemodeTypeDisplayName = {
  [EOptionGamemodeType.OGT_Always]: 'Always',
  [EOptionGamemodeType.OGT_OnlyInMainMenu]: 'Only In Main Menu',
  [EOptionGamemodeType.OGT_OnlyInGame]: 'Only In Game',
};
