export enum EBatteryStatus {
  BS_Idle,
  BS_IdleEmpty,
  BS_IdleFull,
  BS_PowerIn,
  BS_PowerOut,
}

export const EBatteryStatusDisplayName = {
  [EBatteryStatus.BS_Idle]: 'Idle',
  [EBatteryStatus.BS_IdleEmpty]: 'Idle Empty',
  [EBatteryStatus.BS_IdleFull]: 'Idle Full',
  [EBatteryStatus.BS_PowerIn]: 'Power In',
  [EBatteryStatus.BS_PowerOut]: 'Power Out',
};
