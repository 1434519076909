export enum EFGSignificanceType {
  Creature,
  AudioVolume,
  GenericTickHelper,
  ConveyorBelt,
  Factory,
  LowDistanceGainSignificance,
  MidDistanceGainSignificance,
  HighDistanceGainSignificance,
  AmbientSoundSpline,
  CustomDistanceGainSignificance,
  ParticleSignificance,
  TrainSignificance,
  PipelineSignificance,
  MAX,
}
