export enum ELocalizationType {
  LT_Official,
  LT_Community,
  LT_Debug,
}

export const ELocalizationTypeDisplayName = {
  [ELocalizationType.LT_Official]: 'Official',
  [ELocalizationType.LT_Community]: 'Community',
  [ELocalizationType.LT_Debug]: 'Debug',
};
