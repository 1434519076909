export enum EOptionApplyType {
  OAT_Normal,
  OAT_UpdateInstantly,
  OAT_RequireSessionRestart,
  OAT_RequireGameRestart,
}

export const EOptionApplyTypeDisplayName = {
  [EOptionApplyType.OAT_Normal]: 'Normal',
  [EOptionApplyType.OAT_UpdateInstantly]: 'Update Instantly',
  [EOptionApplyType.OAT_RequireSessionRestart]: 'Require Session Restart',
  [EOptionApplyType.OAT_RequireGameRestart]: 'Require Game Restart',
};
